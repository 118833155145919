import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardFooter, Col, CustomInput, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";

import {IAppContext} from "../App";

import Loading from "../../Components/Loading";
import {withContext} from "../../Components/AppContext";
import LocationInput from "../../Components/Inputs/LocationInput";

import {l} from "../../Utils/locales";
import {API, Company, CompanyWithoutID, User} from "../../Utils/APIShapes";
import {Log, setTitle} from "../../Utils/utils";
import {dispatchAlertFromError} from "../../Components/Alerts";


interface State {
  modal: boolean
  newCompany: CompanyWithoutID
  defaultCompanyID: number|undefined
}

class LoginStep2 extends Component<{context: IAppContext}, State> {
  state: State = {modal: false, defaultCompanyID: undefined, newCompany: {active: false} as CompanyWithoutID};

  componentDidMount() {
    if (!this.props.context.loaded)
      document.addEventListener('loaded', () => {
        if (!this.props.context.userAvailableCompanies)
          this.props.context.redirectTo("/login");
        else
          this.updateCheckboxes();
      });
    else this.updateCheckboxes();
    setTitle(l("login.choose-title"));
    this.props.context.setNavBar(false);
  }

  updateCheckboxes = () => {
    if (!this.props.context.userData) return;
    this.setState({defaultCompanyID: this.props.context.userData.defaultCompany?.id});
  };

  onFinish = (v: Company) => {
    return () => {
      this.props.context.setCompany(v);
      this.props.context.redirectTo("/vouchers");
    }
  };

  onChange = e => {
    const {target: {name, value}} = e;
    this.setState(s => {
      // if (!)
      s.newCompany[name] = value;
      return s;
    })
  };
  onLocationChange = v => {
    this.setState(s => {
      s.newCompany.location = v;
      return s;
    });
  }

  onCheck = k => event => {
    event.stopPropagation();
    if (!this.props.context.userData) throw new Error("user data is undefined??");
    const userData: User = this.props.context.userData;
    let removeDefault = (event.target as HTMLInputElement).checked;
    API.put.user(userData.id, {defaultCompany: {id: removeDefault ? 0 : k} as Company}).catch(e => {
      Log.e(e);
      dispatchAlertFromError("Failed to update the user!");
    }).finally(() => {
      API.get.currentUser().then(v => this.props.context.setUserData(v)).finally(this.updateCheckboxes);
    });
  };
  onCheckClick = k => e => {
    if (e.target.checked && e.target.id === `checkbox-${this.state.defaultCompanyID}`) {
      e.target.checked = false;
      this.onCheck(k)(e);
    }
  }

  save = e => {
    e.preventDefault();
    if (this.state.newCompany)
      API.post.company(this.state.newCompany).catch(Log.e).then();
  };

  render() {
    const {userAvailableCompanies: companies} = this.props.context;
    if (!companies) return <div><Loading/>{l("global.loading")}</div>;
    const {newCompany: {regNr, name, location}, defaultCompanyID, modal} = this.state;
    return <div className="account-cont">
      <h3>{l("login.choose")}</h3>
      <Row className="justify-content-start">
        {companies.map(v => <Col key={v.id} xs={12} sm={6} md={4} lg={3}><Card>
          <CardBody onClick={this.onFinish(v)}>{v.name}</CardBody>
          <CardFooter>
            <CustomInput id={`checkbox-${v.id}`} type="radio" name="default-company" label={l("login.default")}
                         onChange={this.onCheck(v.id)} onClick={this.onCheckClick(v.id)} checked={defaultCompanyID === v.id}/>
          </CardFooter>
        </Card></Col>)}
        <Col xs={12} sm={6} md={4} lg={3}><Card onClick={() => this.setState({modal: true})} className="add-new">
          <CardBody className="align-items-center d-flex"><div>{l("login.add-company")}</div></CardBody>
        </Card></Col>
      </Row>
      <Link to="/logout" style={{marginLeft: "15px"}}>
        <Button color="danger">{l("login.logout")}</Button><br/>
      </Link>
      <Modal isOpen={modal} toggle={() => this.setState(s => ({modal: !s.modal}))} autoFocus={false}>
        <form>
          <ModalHeader>{l("login.modal.create")}</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="comp-name">{l("login.modal.name")}</Label>
              <Input type="text" id="comp-name" name="name" value={name || ""} onChange={this.onChange} autoFocus/>
            </FormGroup>
            <FormGroup>
              <Label for="comp-regNr">{l("login.modal.reg-nr")}</Label>
              <Input type="text" id="comp-regNr" name="regNr" value={regNr || ""} onChange={this.onChange}/>
            </FormGroup>
            <FormGroup>
              <Label>{l("login.modal.address")}</Label>
              <LocationInput name="address" autoValue={location} onChange={this.onLocationChange}/>
            </FormGroup>
            {/*<FormGroup>
              <Label>{l("login.email")}</Label>
              <Input type="email" name="email" value={email} onChange={this.onChange}/>
            </FormGroup>*/}
          </ModalBody>
          <ModalFooter>
            <Row className="btns">
              <Button color="success" type="submit" onClick={this.save}>{l("global.save")}</Button>
              <Button onClick={() => this.setState(s => ({modal: !s.modal}))}>{l("global.cancel")}</Button>
            </Row>
          </ModalFooter>
        </form>
      </Modal>
    </div>;
  }
}

export default withContext(LoginStep2);