import React from "react";

import CustomSelect from "./CustomSelect";

import {Icon, Icons} from "../Icon";

import {ls} from "../../Utils/locales";
import {Address, API, Location, PlaceSuggestion, Point, Waypoint} from "../../Utils/APIShapes";
import {typingDelay} from "../../Utils/static";
import {classMap, Log} from "../../Utils/utils";

import "./LocationInput.scss";
import {dispatchAlertFromError} from "../Alerts";


enum Status {
  idle, wait, loading, invalid, serverError
}
const getStatus = status => ({
  [Status.idle]:    {icon: <Icon name={Icons.Map}/>,    emptyVal: ls("location-input.idle")},
  [Status.invalid]: {icon: <Icon name={Icons.Danger}/>, emptyVal: ls("location-input.invalid")},
  [Status.serverError]: {icon: <Icon name={Icons.ServerProblem}/>, emptyVal: ls("location-input.invalid")},
  [Status.wait]:    {icon: <Icon name={Icons.Wait}/>, emptyVal: ls("global.loading")},
  [Status.loading]: {icon: undefined, emptyVal: ls("global.loading")},
}[status]);

type LAP = Location | Address | Point;

const pointToString = (p: Point) => `${p.lat.toFixed(4)}; ${p.lng.toFixed(4)}`;
const lapToString = (v: LAP): string => {
  if ("address" in v) {
    return v.shortName || pointToString(v.point);
  } else {
    if ("fullAddress" in v) {
      return v.fullAddress || pointToString(v.point);
    } else {
      return pointToString(v);
    }
  }
}

const lapMinify = (autoValue) => {
  let autoVal: LAP | undefined = undefined;
  if (autoValue && (
    "point" in autoValue
      ? autoValue.point.lat && autoValue.point.lng
      : autoValue.lat && autoValue.lng)) {

    if ("polyline" in autoValue) { // Waypoint
      autoVal = autoValue.location || autoValue.address || autoValue.point;
    } else autoVal = autoValue;

  }
  return autoVal;
}

interface Props {
  value?: any
  autoValue?: Waypoint|Location|Address|Point
  className?: string
  onChange(d: Address|Location): any
  [x: string]: any
}
interface State {
  text: string
  suggestions: PlaceSuggestion[]
  status: Status
}
export default class LocationInput extends React.Component<Props> {
  state: State = {
    text: "",
    suggestions: [],
    status: Status.idle
  };
  typingTimeout: NodeJS.Timeout|undefined;
  ref: HTMLElement|null = null;

  componentWillUnmount() {
    if (this.typingTimeout) clearTimeout(this.typingTimeout);
  }

  onSelect = (_v) => {
    const v: PlaceSuggestion = JSON.parse(_v) as PlaceSuggestion;
    switch (v.resultType) {
      case "searchResult":
        API.get.autocompleteResults(v.id).then((r) => {
          this.props.onChange(r);
          this.setState({suggestions: [], status: Status.idle});
        }).catch(e => {
          Log.e(e);
          dispatchAlertFromError("Something went wrong!");
          this.setState({status: Status.serverError, text: "", suggestions: []});
        });
        break;
      case "Location":
        this.props.onChange(v as unknown as Location);
        break;
      case "Address":
        this.props.onChange(v as Address);
        break;
    }
  };
  onInput = (v) => {
    if (!v && [Status.invalid, Status.serverError].includes(this.state.status)) return;
    this.setState({text: v, status: Status.wait});
    if (this.typingTimeout) clearTimeout(this.typingTimeout);
    this.typingTimeout = setTimeout(this.getSuggestions, typingDelay);
  };

  getSuggestions = () => {
    const {text} = this.state;
    if (text === "") {
      this.setState({status: Status.idle});
      return;
    }
    this.setState({status: Status.loading}, async () => {
      let errored = false;
      const val = await API.get.autocompletePlace(encodeURI(text.trim())).catch(e => {
        Log.e(e);
        dispatchAlertFromError("Something went wrong!");
        errored = true;
        this.setState({suggestions: [], status: Status.serverError});
      });
      if (!errored)
        this.setState({suggestions: val || [], status: val ? Status.idle : Status.invalid});
    });
  };

  render() {
    const {suggestions, status} = this.state;
    const {className, value, autoValue} = this.props;
    const {icon, emptyVal} = getStatus(status);
    const isError = [Status.invalid, Status.serverError].includes(status);
    const autoVal = lapMinify(autoValue);
    if (value) {
      return <CustomSelect onChange={this.onSelect} className={classMap("loc", className)} emptyValue={emptyVal}
                           customIcon={icon} onInput={this.onInput} loading={status === Status.loading} value={"__"}
                           invalid={isError} customInvalidIcon={isError ? icon : null}>
        <option value="__">{value}</option>
      </CustomSelect>;
    } else if (autoVal) {
      return <CustomSelect onChange={this.onSelect} className={classMap("loc", className)} emptyValue={emptyVal}
                           customIcon={icon} onInput={this.onInput} loading={status === Status.loading} value={JSON.stringify(autoVal)}
                           invalid={isError} customInvalidIcon={isError ? icon : null}>
        <option value={JSON.stringify(autoVal)}>{lapToString(autoVal)}</option>
      </CustomSelect>;
    } else {
      return <CustomSelect onChange={this.onSelect} className={classMap("loc", className)} emptyValue={emptyVal}
                           customIcon={icon} onInput={this.onInput} loading={status === Status.loading}
                           invalid={isError} customInvalidIcon={isError ? icon : null}>
        {suggestions.map(v => <option key={v.id} value={JSON.stringify(v)}>{v.fullAddress}</option>)}
      </CustomSelect>;
    }
  }
}