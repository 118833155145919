import React from "react";

import "./Alerts.scss";
import {classMap} from "../Utils/utils";
import {popupAlertAnimLength, popupAlertLength} from "../Utils/static";
import {withContext} from "./AppContext";
import {IAppContext} from "../Views/App";
import {Icon, Icons} from "./Icon";

export enum Severity {
  SUCCESS, INFO, WARNING, ERROR
}

interface AlertEvent {
  severity: Severity
  message: string
  fresh?: boolean
  fading?: boolean
}

interface State {
  ongoingAlerts: AlertEvent[]
}

class Alerts extends React.Component<{context: IAppContext}, State> {
  state: State = {
    ongoingAlerts: []
  }
  // activeTimeouts: number[] = [];

  componentDidMount() {
    document.addEventListener("alert-dispatch", this.dispatchedAlert);
  }
  componentWillUnmount() {
    document.removeEventListener("alert-dispatch", this.dispatchedAlert);
  }

  dispatchedAlert = (event: Event) => {
    const {detail} = event as CustomEvent<AlertEvent>;
    detail.fresh = true;
    detail.fading = false;
    this.setState(s => {
      s.ongoingAlerts.push(detail);
      return s;
    }, () => {
      window.setTimeout(() => {
        this.setState(s => {
          const e = s.ongoingAlerts.find(v => v === detail);
          if (e) e.fresh = false;
          return s;
        }, () => {
          window.setTimeout(() => {
            const i = this.state.ongoingAlerts.findIndex(v => v === detail);
            if (i >= 0) this.closeAlert(i)();
          }, popupAlertLength);
        });
      }, popupAlertAnimLength);
    });
  }

  closeAlert = (i: number) => () => {
    this.setState(s => {
      const e = s.ongoingAlerts[i];
      if (e) e.fading = true;
      return s;
    }, () => {
      window.setTimeout(() => {
        this.setState(s => {
          if (i >= 0) s.ongoingAlerts.splice(i, 1);
          return s;
        });
      }, popupAlertAnimLength);
    });
  }

  render() {
    return <div className={classMap("popup-alert__container", this.props.context.navBar && "popup-alert__container--lower")}>
      {this.state.ongoingAlerts.filter(v => v).map((a, i) =>
        <div className={classMap("popup-alert", `popup-alert--${Severity[a.severity].toLowerCase()}`,
          a.fading && "popup-alert--fading", a.fresh && "popup-alert--entering")}
             key={i}>{a.message}<Icon name={Icons.Close} onClick={this.closeAlert(i)}/></div>
      )}
    </div>
  }
}


export const dispatchAlert = (severity: Severity, message: string) => {
  document.dispatchEvent(new CustomEvent("alert-dispatch", {detail: {severity: severity, message: message}}))
}
export const dispatchAlertFromError = (error: any) => {
  if (error instanceof Error) {
    dispatchAlert(Severity.ERROR, error.name + ": " + error.message);
  } else {
    dispatchAlert(Severity.WARNING, error);
  }
}

export default withContext(Alerts);