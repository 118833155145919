import React from "react";
import * as Scroll from "react-scroll/modules";
import {Alert, Button, Card, CardBody, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import {dispatchAlert, dispatchAlertFromError, Severity} from "../../Components/Alerts";

import BP from "../../Components/BP";
import {Icon, Icons} from "../../Components/Icon";
import CustomSelect from "../../Components/Inputs/CustomSelect";
import LocationInput from "../../Components/Inputs/LocationInput";
import Loading from "../../Components/Loading";
import {PaginationData} from "../../Utils/API";

import {API, Driver, ID} from "../../Utils/APIShapes";
import {l, ls} from "../../Utils/locales";
import {Log, setTitle} from "../../Utils/utils";

import {IAppContext} from "../App";


export interface EditableListProps {
  context: IAppContext
}

export interface EditableListState<T> {
  modalOpen: boolean
  activeModal: number
  alertState: number
  loading: boolean
  items: T[]
  choices?: {driver?: Driver[]}
  choicesLoading?: {driver: boolean}
}

export enum ItemPropType {
  TEXT = "text",
  NUMBER = "number",
  LOCATION = "location",
  DRIVER = "driver"
}

export interface Field<T> {
  name: string
  type?: ItemPropType // default: ItemPropType.Text
  locale?: string // default: same as `name`
  showInList?: boolean // default: true
  getValue?(item: T): any
  required?: boolean // default: true
}
interface FullField<T> extends Field<T> {
  getValue(item: T): string
}

interface APICalls<T> {
  get(pd: PaginationData), post(item: T), put(id: number, item: T), del(id: number)
}

export default abstract class EditableList<T extends ID, Props extends EditableListProps, State extends EditableListState<T>> extends React.Component<Props, State> {
  modalRef: HTMLFormElement|null = null;
  type: string; title: string;
  fields: FullField<T>[];
  apiCalls: APICalls<T>;

  protected constructor(props, type: string, title: string, fields: (string|Field<T>)[], apiCalls: APICalls<T>) {
    super(props);
    this.type = type;
    this.title = title;
    this.fields = fields.map(v => typeof v === "string" ? {name: v, getValue: i => i[v]} : {getValue: i => i[v.name], ...v});
    this.apiCalls = apiCalls;
  }


  componentDidMount() {
    this.refresh();
    this.props.context.setNavBar(true);
    setTitle(l(this.title));
  }


  openEditModal = (id: number) => event => {
    event.preventDefault();
    this.setState({activeModal: id, modalOpen: true}, () => {
      setTimeout(() => this.forceUpdate());
    });
  }
  closeEditModal = () => {
    this.setState({activeModal: -1, modalOpen: false}, this.refresh);
  }
  openNewModal = (event) => {
    event.preventDefault();
    this.setState(s => ({
      items: [...s.items, this.blankItem()],
      modalOpen: true, activeModal: s.items.length
    }))
  };

  abstract blankItem(): any;

  beforeSave = (cb) => {cb();}

  refresh = () => {
    this.setState({loading: true});
    this.apiCalls.get({count: 50})
      .then(v => this.setState(() => ({items: v || [], loading: false})))
      .catch(() => this.setState({loading: false, alertState: -3}));

    if (this.fields.find(v => v.type === ItemPropType.DRIVER)) {
      API.get.drivers({count: 50}).then(v => this.setState(s => (
        {choices: {...s.choices, driver: v || []}, choicesLoading: {...s.choicesLoading, driver: false}}))).catch(e => {
        Log.e(e);
        dispatchAlertFromError("Failed to fetch drivers!");
      });
    }
  };
  deleteItem = (event) => {
    event.preventDefault();
    if (window.confirm(ls(`settings.confirm-del.${this.type}`))) {
      const item = this.state.items[this.state.activeModal];
      if (item.id === undefined) {
        this.setState(s => ({items: s.items.filter(v => v !== item)}))
      } else {
        this.apiCalls.del(item.id)
          .then(() => {
            this.setState({alertState: 2}, Scroll.animateScroll.scrollToTop);
            this.refresh();
          })
          .catch(() => this.setState({alertState: -2}, Scroll.animateScroll.scrollToTop));
      }
      this.closeEditModal();
    }
  };
  save = async () => {
    this.beforeSave(() => {
      const item: T = this.state.items[this.state.activeModal];

      if (!this.fields.filter(v => v.required !== false).every(f => item[f.name])) {
        dispatchAlert(Severity.WARNING, "Incomplete!");
        return;
      }

      (item.id ? this.apiCalls.put(item.id, item) : this.apiCalls.post(item))
        .then(() => {
          this.setState({alertState: 1}, Scroll.animateScroll.scrollToTop);
          this.refresh();
        }).catch(() => this.setState({alertState: -1}, Scroll.animateScroll.scrollToTop));
      this.closeEditModal();
    });
  };
  onChange = ({target: {name, value}}) => {
    this.setState((s: State) => {
      const field = this.fields.find(v => v.name === name);
      if (!field) return s;
      const items = [...s.items];
      const item = {...items[s.activeModal]};
      item[name] = field.type === ItemPropType.NUMBER ? parseFloat(value) : value;
      items[s.activeModal] = item;
      return {items};
    })
  };
  onLocationChange = (name) => (value) => {
    this.setState(s => {
      s.items[s.activeModal][name] = value;
      return s;
    })
  }
  onDriverChange = (name) => (id: number) => {
    const ref = this.state.choices?.driver?.filter(v => v.id === id)[0];
    Log.d(ref);
    this.setState(s => {
      s.items[s.activeModal][name] = ref;
      return s;
    });
  }


  getInput = (v: FullField<T>, item: T, index: number) => {
    if (v.type === ItemPropType.LOCATION) {
      return <LocationInput onChange={this.onLocationChange(v.name)}/>
    } else if (v.type === ItemPropType.DRIVER) {
      const {choices, choicesLoading} = this.state;
      return <CustomSelect onChange={this.onDriverChange(v.name)} searchable value={v.getValue(item)}
                           loading={choicesLoading?.driver}>
        {choices?.driver?.map((d: Driver) => <option key={d.id} value={d.id}>{d.name} {d.surname}</option>)}
      </CustomSelect>
    } else {
      return <Input name={v.name} type={v.type || ItemPropType.TEXT} value={v.getValue(item) || ""}
                    onChange={this.onChange} required={v.required !== false} autoFocus={index === 0}/>
    }
  }

  render() {
    const {alertState, items, loading, activeModal, modalOpen} = this.state;
    return <div className="body">
      <>
        <Alert color="success" isOpen={alertState ===  1} toggle={() => this.setState({alertState: 0})}>{l(`settings.alert.save.${this.type}.saved`)}</Alert>
        <Alert color="danger"  isOpen={alertState === -1} toggle={() => this.setState({alertState: 0})}>{l(`settings.alert.save.${this.type}.failed`)}</Alert>
        <Alert color="success" isOpen={alertState ===  2} toggle={() => this.setState({alertState: 0})}>{l(`settings.alert.delete.${this.type}.saved`)}</Alert>
        <Alert color="danger"  isOpen={alertState === -2} toggle={() => this.setState({alertState: 0})}>{l(`settings.alert.delete.${this.type}.failed`)}</Alert>
        <Alert color="danger"  isOpen={alertState === -3} toggle={() => this.setState({alertState: 0})}>{l("global.refresh-failed")}</Alert>
      </>
      <BP on="xs" elem="a" href="/" className="new-v-link" onClick={this.openNewModal}>{l(`settings.add-new.${this.type}`)}</BP>
      <BP off="xs" elem={Table}>
        <thead>
        <tr>
          <th>{l("global.actions.edit")}</th>
          {this.fields.filter(v => v.showInList !== false).map((v, i) => <th key={i}>{l(`settings.fields.${this.type}.${v.locale || v.name}`)}</th>)}
        </tr>
        </thead>
        <tbody>
        {items.filter((v, i) => i !== activeModal).map((item, k) => <tr key={k}>
          <td>
            <Icon name={Icons.Edit} hoverText={ls("global.actions.edit")} onClick={this.openEditModal(k)}/>
          </td>
          {this.fields.filter(v => v.showInList !== false).map((v, i) => <td key={i}>{v.getValue(item)}</td>)}
        </tr>)}
        </tbody>
      </BP>
      <BP on="xs">
        {items.filter((v, i) => i !== activeModal).map((item, k) => <Card key={k}><CardBody>
          {this.fields.filter(v => v.showInList !== false).map((v, i) => <p key={i}><b>{l(`settings.fields.${this.type}.${v.locale || v.name}`)}</b><br/>{v.getValue(item)}</p>)}
          <div className="btns">
            <Button color="primary" onClick={this.openEditModal(k)}>{l("global.actions.edit")}</Button>
          </div>
        </CardBody></Card>)}
      </BP>
      {loading ? <Loading/> : undefined}
      <a href="/" className="new-v-link" onClick={this.openNewModal}>{l(`settings.add-new.${this.type}`)}</a>

      <Modal isOpen={modalOpen} toggle={this.closeEditModal} autoFocus={false}>
        <form ref={r => this.modalRef = r}>
          <ModalHeader>{l(`vouchers.modals.create-${this.type}`)}</ModalHeader>
          <ModalBody>
            {this.fields.map((v, index) =>
              <FormGroup key={index}>
                <Label>{l(`settings.fields.${this.type}.${v.locale || v.name}`)}</Label>
                {this.getInput(v, items[activeModal] || this.blankItem(), index)}
              </FormGroup>
            )}
          </ModalBody>
          <ModalFooter>
            <Row>
              <a href="/" className="remove-link" onClick={this.deleteItem}>{l("global.actions.delete")}</a>
            </Row>
            <Row className="btns">
              <Button color="success" onClick={this.save}>{l("global.save")}</Button>
              <Button onClick={this.closeEditModal}>{l("global.cancel")}</Button>
            </Row>
          </ModalFooter>
        </form>
      </Modal>
    </div>;
  }
}