import React from 'react';
import {Link} from 'react-router-dom';

import {classMap} from "../Utils/utils";

import './Icon.sass';

export enum Icons {
  Edit, Remove,
  Finished, Unfinished,
  ArrowDown, ArrowLeft, ArrowRight,
  Calendar, Map, Fuel,
  Search, Danger, Wait,
  Close,
  FavActive, FavInactive,
  ServerProblem
}

const iconMap: {[x in Icons]: {viewBox: string, data: React.ReactNode}} = {
  [Icons.Edit]:         {viewBox: '0 0 20 20',    data: <path d='M0 20h 4l11.5-11.5l-4-4l-11.5 11.5ZM13 3l1-1q1-1 2 0l2 2q1 1 0 2l-1 1Z'/>},
  [Icons.Remove]:       {viewBox: '0 0 40 40',    data: <path d='M6 10v24q0 5 5 5h18q5 0 5-5v-24ZM3 7.5v-4h10q3 0 3-3h8q0 3 3 3h10v4Z'/>},
  [Icons.Finished]:     {viewBox: '-.5-.5 12 12', data: <path d='M10 3l-6 6-3-3 1-1 2 2 5-5z'/>},
  [Icons.Unfinished]:   {viewBox: '-5-5 10 10',   data: <circle r='1'/>},
  [Icons.ArrowDown]:    {viewBox: '0 0 8 8',      data: <path d='M0 3l4 4 4-4-1-1-3 3-3-3'/>},
  [Icons.ArrowLeft]:    {viewBox: '0 0 12 12',    data: <path d='M7 2l-4 4 4 4 1-1-3-3 3-3'/>},
  [Icons.ArrowRight]:   {viewBox: '0 0 12 12',    data: <path d='M5 2l 4 4-4 4-1-1 3-3-3-3'/>},
  [Icons.Calendar]:     {viewBox: '0 0 24 24',    data: <path d='M6 1L6 3 5 3C4 3 3 4 3 5L3 19C3 20 4 21 5 21L19 21C20 21 21 20 21 19L21 5C21 4 20 3 19 3L18 3 18 1 16 1 16 3 8 3 8 1 6 1zM5 8L19 8 19 19 5 19 5 8zM7 10l2 0 0 2-2 0zM11 10l2 0 0 2-2 0zM15 10l2 0 0 2-2 0z'/>},
  [Icons.Map]:          {viewBox: '0 0 24 24',    data: <path d='M3 5l6-2 6 2 6-2v16l-6 2-6-2-6 2zM9 5v12l6 2v-12z'/>},
  [Icons.Fuel]:         {viewBox: '0 0 24 24',    data: <path d="M7 2C5.5 2 4 3.5 4 5V20H2v2H18v-2h-2v-2h2C20 18 21 17 21 15L21 7.7 17.5 4.2 16.2 5.5 18.2 7.5 18 9C18 9.5 18.5 10 19 10L19 15C19 15.5 18.5 16 18 16H16V5C16 3.5 14.5 2 13 2H5zM7 4h6C13.5 4 14 4.5 14 5v4H6V5C6 4.5 6.5 4 7 4z"/>},
  [Icons.Search]:       {viewBox: '0 0 64 64',    data: <path d="M0,24A24,24,0,0,0,48,24A24,24,0,0,0,0,24Z M5,24A14,14,0,0,1,43,24A14,14,0,0,1,5,24 M38,42L60,64L64,60L42,38Z"/>},
  [Icons.Danger]:       {viewBox: '0 0 80 80',    data: <path d="M40 0 L80 80 L0 80Z M40 15 L11.25 72.5 L68.75 72.5Z M35 62 a5,5 0 1,0 10,0 a5,5 0 1,0 -10,0Z M35 33L38 54 h4 L45 33"/>},
  [Icons.Wait]:         {viewBox: '-10-10 20 20', data: <path d="M-10 0 A10,10 0 1,0 10,0 A10,10 0 1,0 -10,0Z M-8 0 A8,8 0 0,1 8,0 A8,8 0 0,1 -8,0Z M-1 0 A1 1,0,1 0,1 0V-5 A1 1,0,1 0,-1 -5Z M-.7 .7 l2 2 a.7 .7,0,0 0,1.4-1.4 l-2 -2"/>},
  [Icons.Close]:        {viewBox: '0 0 16 16',    data: <path d="M1 1L15 15M1 15L15 1" stroke="#000" strokeWidth={2}/>},
  [Icons.FavActive]:    {viewBox: '-12 -12 24 24',data: <path d="M0-10,2.245-3.09,9.51-3.09,3.633 1.18,5.878 8.09,0 3.82,-5.878 8.09,-3.633 1.18,-9.51-3.09,-2.245-3.09Z" stroke="#000" fill="#000"/>},
  [Icons.FavInactive]:  {viewBox: '-12 -12 24 24',data: <path d="M0-10,2.245-3.09,9.51-3.09,3.633 1.18,5.878 8.09,0 3.82,-5.878 8.09,-3.633 1.18,-9.51-3.09,-2.245-3.09Z" stroke="#000" fill="#0000"/>},
  [Icons.ServerProblem]:{viewBox: '0 0 160 160',  data: <path d="M120 0 L160 80 L80 80Z M120 15 L91.25 72.5 L148.75 72.5Z M115 62 a5,5 0 1,0 10,0 a5,5 0 1,0 -10,0Z M115 33L118 54 h4 L125 33Z M30 20 H102.5 L98.75 27.5 H37.5 V132.5 H102.5 V87.5 H110 V140 H30Z M45 35 H95 L92.5 40 H50 v10 H87.5 L85 55 H45Z M45 65 H80 L77.5 70 H50 v10 H72.5 L70 85 H45Z M45 95 H95 v5 v10 v5 H45Z M50 100 v10 H90 v-10Z"/> }
};

interface IconProps {
  name: Icons,
  onClick?(e: React.MouseEvent),
  href?: string,
  big?: boolean,
  className?: string,
  hoverText?: string,
  [x: string]: any
}

export const Icon = ({onClick, href, big, name, className, hoverText, ...rest}: IconProps) => {
  const icon = <svg className={classMap("ico", big && "big")} viewBox={iconMap[name].viewBox}>{iconMap[name].data}</svg>;
  const noLink = onClick ? <span onClick={onClick}>{icon}</span> : icon;

  return <span {...rest} className={classMap("icon", hoverText && "hover-cont", className)}>
    {href ? <Link to={href} onClick={onClick}>{icon}</Link> : noLink}
    {hoverText ? <span className="hover-body">{hoverText}</span> : undefined}
  </span>;
};