import React, {Component} from "react";
import {Link} from "react-router-dom";
import dateFormat from "dateformat";
import {Button, Card, CardBody, Table} from "reactstrap";

import {IAppContext} from "../App";

import BP from "../../Components/BP";
import {Icon, Icons} from "../../Components/Icon";
import Loading from "../../Components/Loading";
import {withContext} from "../../Components/AppContext";

import {API, Route, Voucher as IVoucher} from "../../Utils/APIShapes";
import {l, ls} from "../../Utils/locales";
import {Log, setTitle} from "../../Utils/utils";

import "./Vouchers.scss";
import {dispatchAlertFromError} from "../../Components/Alerts";


interface State {
  vouchers: IVoucher[],
  loading: boolean
}

class Voucher extends Component<{context: IAppContext}, State> {
  state: State = {vouchers: [], loading: true};

  componentDidMount() {
    API.get.vouchers({count: 50}).then(v => this.setState({vouchers: v || []})).catch(e => {
      Log.e(e);
      dispatchAlertFromError("Failed to fetch vouchers!");
    }).finally(() => this.setState({loading: false}));

    setTitle(l("titles.vouchers"));
    this.props.context.setNavBar(true);
  }

  delete = id => event => {
    event.preventDefault();
    if (window.confirm(ls("vouchers.confirm-del"))) {
      this.setState({loading: true}, () => {
        API.del.voucher(id).then(
          () => API.get.vouchers({count: 50}).then(v => this.setState({vouchers: v || []})).catch(e => {
            Log.e(e);
            dispatchAlertFromError("Failed to fetch vouchers!");
          }).finally(() => this.setState({loading: false}))
        ).catch(e => {
          Log.e(e);
          dispatchAlertFromError("Failed to delete voucher!");
        });
      });
    }
  };

  calcPeriod = (routes: Route[]): string => {
    if (!routes || routes.length === 0) return "-";
    else if (routes.length === 1) return dateFormat(new Date(routes[0].date!), "dd/mm/yyyy");
    const routeDates = routes.map(r => r.date).filter(v => v).sort() as number[];
    return `${dateFormat(new Date(routeDates[0]), "dd/mm/yyyy")} - ${dateFormat(new Date(routeDates[routeDates.length - 1]), "dd/mm/yyyy")}`;
  };

  render() {
    if (this.state.loading) {
      return <div>
        <header>
          <h3>{l("vouchers.list")}</h3>
          <BP off="xs" elem={Link} to={"/vouchers/new"} className={"new-v-link"}>{l("vouchers.add-new")}</BP>
        </header>
        <Loading/>
      </div>
    }
    return <div>
      <header>
        <h3>{l("vouchers.list")}</h3>
        <BP off="xs" elem={Link} to={"/vouchers/new"} className={"new-v-link"}>{l("vouchers.add-new")}</BP>
      </header>
      {this.state.vouchers.length === 0 ?
      <div className="center empty">
        <p>{l("vouchers.empty")}</p>
        <Link to={"/vouchers/new"} className={"new-v-link"}>{l("vouchers.create")}</Link>
      </div> : <div className="body">
        <BP on="xs" elem={Link} to={"/vouchers/new"} className={"new-v-link"}>{l("vouchers.add-new")}</BP>
        <BP off="xs" elem={Table}>
          <thead>
          <tr>
            <th>{l("vouchers.fields.actions")}</th>
            {/*<th>{l("vouchers.fields.name")}</th>*/}
            <th>{l("vouchers.fields.period")}</th>
            <th>{l("vouchers.fields.driver")}</th>
            <th>{l("vouchers.fields.car")}</th>
            <th className="finish-col">{l("vouchers.fields.finished")}</th>
          </tr>
          </thead>
          <tbody>
          {this.state.vouchers.map((v, k) => <tr key={k}>
            <td>
              <Icon name={Icons.Edit} href={`/vouchers/edit/${v.id}`} hoverText={ls("global.actions.edit")} onClick={() => this.props.context.setData(v)}/>
              <Icon name={Icons.Remove} href="/" hoverText={ls("global.actions.delete")} onClick={this.delete(v.id)}/>
            </td>
            {/*<td>{v.name || v.vNr || `Voucher #${v.id}`}</td>*/}
            <td>{this.calcPeriod(v.routes!)}</td>
            <td>{v.driver.name + " " + v.driver.surname}</td>
            <td>{v.car.nrPlate}</td>
            <td className={(!v.finished ? "finish " : "") + "finish-col"}>
              <Icon name={v.finished ? Icons.Finished : Icons.Unfinished}/>
            </td>
          </tr>)}
          </tbody>
        </BP>
        <BP on="xs">
          {this.state.vouchers.map((v, k) => <Card key={k}><CardBody>
            {/*<p><b>{l("vouchers.fields.name")}</b><br/>{v.name || v.vNr || `Voucher #${v.id}`}</p>*/}
            <p><b>{l("vouchers.fields.period")}</b><br/>{this.calcPeriod(v.routes!)}</p>
            <p><b>{l("vouchers.fields.driver")}</b><br/>{v.driver.name + " " + v.driver.surname}</p>
            <p><b>{l("vouchers.fields.car")}</b><br/>{v.car.nrPlate}</p>
            <div className="btns">
              <Button color="primary" onClick={() => {
                this.props.context.setData(v);
                this.props.context.redirectTo(`/vouchers/edit/${v.id}`);
              }}>{l("global.actions.edit")}</Button>
              <Button color="danger" onClick={this.delete(v.id)}>{l("global.actions.delete")}</Button>
            </div>
          </CardBody></Card>)}
        </BP>
        <Link to={"/vouchers/new"} className={"new-v-link"}>{l("vouchers.add-new")}</Link>
      </div>}
    </div>;
  }
}

export default withContext(Voucher);