import React, {ReactNode} from 'react';
import FuzzySearch from 'fuzzy-search';

import {Icon, Icons} from "../Icon";
import Loading from "../Loading";

import DropdownInput from "./DropdownInput";

import {l} from "../../Utils/locales";
import {childrenToString, classMap} from "../../Utils/utils";

import './CustomSelect.scss';


export const equals = (a, b) => a && b && a.toString().trim() === b.toString().trim();

interface Props {
  onChange(v: any)
  value?: any
  className?: string
  placeholder?: string
  emptyValue?: string
  searchable?: boolean
  loading?: boolean
  customIcon?: JSX.Element
  customInvalidIcon?: JSX.Element
  onInput?(v)
  id?: string
  invalid?: boolean
}
interface State {
  search: string
  filter: boolean
  invalid: boolean
}

const keyModifiers = {"ArrowUp": -1, "ArrowDown": +1};

class CustomSelect extends React.Component<Props, State> {
  state: State = {
    search: "",
    filter: false,
    invalid: false
  };
  searcher: any;
  prevVal: any;
  input: null|HTMLInputElement = null;
  body: null|HTMLDivElement = null;
  open: boolean = false;
  lastActive: any;

  constructor(props) {
    super(props);
    this.searcher = new FuzzySearch(this.getChildrenValues(), ["text"], {sort: true});
  }

  componentDidUpdate(prevProps: Readonly<Props> & Readonly<{children?: ReactNode}>, prevState: State, snapshot) {
    if (prevProps.children !== this.props.children) {
      this.searcher.haystack = this.getChildrenValues();
      this.updateFromPropValue();
    }
    if (prevProps.value !== this.props.value) this.updateFromPropValue()
    if (prevProps.invalid !== this.props.invalid) this.setState({invalid: this.props.invalid || false});
  }

  updateFromPropValue = () => {
    if (!this.props.value) return;
    const selected = (this.getChildrenValues() || []).filter(({value: v}) => equals(v, this.props.value))[0];
    this.setState({search: (selected && selected.text) || this.prevVal || ""});
  };


  save = (value?) => {
    const {search} = this.state;
    const selected = (this.getChildrenValues() || []).filter(({text: v}) => equals(v, value || search))[0];
    if (selected) this.props.onChange(selected.value);
    else this.props.onInput && this.props.onInput(this.prevVal || "");
    this.setState({search: selected ? selected.text as string : search, invalid: !selected});
  };

  onFocus = () => {
    const dae = document.activeElement as HTMLInputElement;
    dae.selectionStart = dae.selectionEnd = 10000;
  };
  onOpen = () => {
    this.open = true;
    const dae = document.activeElement as HTMLInputElement;
    dae.selectionStart = 0;
    dae.selectionEnd = 10000;
    if (!this.state.invalid) this.prevVal = this.state.search;
    this.setState({filter: false});
  };
  onClose = () => {
    this.open = false;
    const dae = document.activeElement as HTMLInputElement;
    if (document.activeElement === this.input)
      dae.selectionStart = dae.selectionEnd = 10000;
    this.save();
  };

  click = ({target}: React.MouseEvent) => {
    if (!this.input || !target) return;
    const value = (target as HTMLSelectElement).value;
    this.input.focus();
    const selected = (this.getChildrenValues() || []).filter(({value: v}) => equals(v, value))[0];
    this.setState({search: (selected && selected.text) || this.prevVal || ""}, this.save);
  };

  getChildrenValues = () => {
    return React.Children.map(this.props.children, (child: any) => ({
      value: child.props.value || childrenToString(child.props.children),
      text: childrenToString(child.props.children),
      self: child,
    }));
  };

  searchChange = ({target: {value}}) => {
    if (!this.body) return;
    if (this.props.onInput) this.props.onInput(value);
    if (this.open) this.setState({search: value, filter: this.props.searchable && value, invalid: false}, () => {
      if (this.state.filter && this.body) {
        const res = this.searcher.search(this.state.search)[0];
        if (!res) return;
        this.focus(([...this.body.children] as HTMLSelectElement[]).filter(({value: v}) => equals(v, res.value))[0]);
      }
    });
  };


  handleTabEnterOpen = () => {
    const edit = !this.state.filter && this.prevVal !== this.state.search,
      sel = this.lastActive;
    if (edit && sel) {
      this.setState({search: sel.innerText}, () => this.save(sel.innerText));
    }
  }

  handleUpDownOpen = (key) => {
    if (!this.body) return;

    const modifier = keyModifiers[key];
    const c = [...this.body.children],
      i = c.indexOf(this.lastActive) + modifier;

    if (i >= 0 && i < c.length) this.focus(c[i]);
  }

  handleUpDownClosed = (key) => {
    if (!this.body) return;

    const modifier = keyModifiers[key];
    const c = [...this.body.children] as HTMLElement[], i = c.indexOf(this.lastActive) + modifier;
    if (i >= 0 && i < c.length) {
      this.setState({search: c[i].innerText}, this.save);
      this.focus(c[i]);
    }
  }

  keyDown = e => {
    if (!this.body) return;

    if (this.open) {
      if (e.key === "Enter" || e.key === "Tab") {
        e.preventDefault();
        this.handleTabEnterOpen();
      } else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        this.handleUpDownOpen(e.key);
      }
    } else {
      if (["ArrowDown", "ArrowUp"].includes(e.key)) {
        e.preventDefault();
        this.handleUpDownClosed(e.key);
      }
    }
  };

  focus = elem => {
    if (this.lastActive) this.lastActive.classList.remove("hover");
    if (elem.isSameNode(this.body)) return;
    this.lastActive = elem;
    if (elem) {
      elem.classList.add("hover");
      if (!this.body || !this.input) return;
      const elemTop = elem.offsetTop - this.body.scrollTop - this.input.offsetHeight + 2;
      if (elemTop < 0) this.body.scrollTop = elem.offsetTop - elem.offsetHeight;
      if ((elemTop + elem.offsetHeight) > this.body.offsetHeight) this.body.scrollTop = elem.offsetTop - this.body.offsetHeight + elem.offsetHeight - this.input.offsetHeight;
    }
  };

  mouseOver = ({target}) => this.focus(target);

  render() {
    const {children, className, placeholder, emptyValue, searchable, loading, customIcon, customInvalidIcon, id} = this.props;
    const {search, filter, invalid} = this.state;
    const options = filter ? this.searcher.search(search).map(v => v.self) : React.Children.toArray(children);
    const normalIcon = loading ? <Loading/> : customIcon || <Icon name={Icons.ArrowDown} className="head-arrow"/>;
    return <DropdownInput value={search} onChange={this.searchChange} className={classMap("select", className, loading && "select-loading", invalid && "invalid")}
                          autoClose ref={r => this.input = r as HTMLInputElement} keyDown={this.keyDown} onOpen={this.onOpen} onClose={this.onClose}
                          onFocus={this.onFocus} beforeInput={searchable ? <Icon name={Icons.Search} className="head-search"/> : undefined}
                          afterInput={invalid ? (customInvalidIcon || <Icon name={Icons.Danger}/>) : normalIcon}
                          placeholder={placeholder} id={id}>
      <div className="body" onClick={this.click} onMouseOver={this.mouseOver} ref={r => this.body = r}>
        {(options && options.length > 0) ? options : <option disabled>{emptyValue || l("misc.select-empty")}</option>}
      </div>
    </DropdownInput>
  }
}

export default CustomSelect;