import React from 'react';

import {gridSize} from "../Views/App";

import {breakpoints} from "../Utils/static";


const m = a => (breakpoints[a] || {}).index;
export const helper = {
  on:    on    => on    && (on  === gridSize),
  off:   off   => off   && (off !== gridSize),
  above: above => above && (m(above) <= m(gridSize)),
  below: below => below && (m(below) >= m(gridSize))
};

interface Props {
  elem?: any,
  on?: string, off?: string,
  below?: string, above?: string,
  [x: string]: any
}

const BP = ({elem: T = "div", on, above, below, off, ...rest}: Props) => {
  if (helper.on(on) || helper.off(off) || helper.above(above) || helper.below(below))
    return <T {...rest}/>;
  return null;
}

export default BP;
