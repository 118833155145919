import React from "react";
import {Modal} from "reactstrap";

import {DropdownInputPure, DropdownProps} from "./DropdownInput";

import {classMap} from "../../Utils/utils";

import "./ModalInput.scss";


class ModalInput extends DropdownInputPure {
  modal: HTMLElement|null = null;

  bodyClick = ({target}) => {
    if (!this.ref || !this.modal) return;
    if (!this.props.autoClose && (this.ref.contains(target) || (this.modal.firstChild && this.modal.firstChild.contains(target)))) return;
    this.close();
  };

  render() {
    const {placeholder, beforeInput, afterInput, children, className, value, onChange, forwardedRef, onFocus, onBlur} = this.props;
    const {open} = this.state;
    return <div className={classMap("modal-input", className, open && "open")}
                ref={r => this.ref = r} onFocus={onFocus} onBlur={onBlur}>
      {beforeInput}
      <input className="head" type="text" ref={forwardedRef} value={value} onChange={onChange} placeholder={placeholder}
             onKeyDown={this.keyDown} onClick={this.open}/>
      {afterInput}
      <div className="body-wrap">
        <Modal isOpen={open} className="modal-cont" innerRef={r => this.modal = r} autoFocus>
          {children}
        </Modal>
      </div>
    </div>;
  }
}

export default React.forwardRef((props: DropdownProps, ref) => <ModalInput {...props} forwardedRef={ref}/>)