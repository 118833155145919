export const breakpoints: {[x: string]: {index, min}} = {
  xs: {index: 0, min: 0},
  sm: {index: 1, min: 576},
  md: {index: 2, min: 768},
  lg: {index: 3, min: 992},
  xl: {index: 4, min: 1200},
};

export const animLength = 150; // ms
export const typingDelay = 750; // ms
export const doubleClickLength = 800; // ms
export const popupAlertLength = 5000; // ms
export const popupAlertAnimLength = animLength; // ms
export const cookieAllowStorage = "allowCookies";
export const currentLangStorage = "defaultLanguage";
