import {Log, trimLeft, trimRight} from "./utils";
import {dispatchAlert, Severity} from "../Components/Alerts";
import {env} from "./Environment";

const fetchTimeout = 8000; // ms

const endpoint = path => {
  return path.startsWith(env.api_path) ?
    path :
    `${trimRight(env.api_path, "/")}/${trimLeft(path, "/")}`;
};

export class StatusError extends Error {
  code: any;
  constructor(code) {
    super();
    this.code = code;
  }
}

const parseRes = async res => {
  const ct = res.headers.get("content-type") || [];
  if (ct.includes("application/json")) return {res: res, out: await res.json()};
  else if (ct.includes("text/plain")) return {res: res, out: await res.text()};
  else return {res: res, out: await res.blob()};
};

export interface PaginationData {
  search?: string
  order?: "asc"|"desc"|string
  offset?: number
  count: number
}

export const fetchParsePaginated = async (method: string, path: string, pd: PaginationData, data?: any) => {
  const url = new URL(endpoint(path));
  for (const pdk in pd) {
    if (!pd.hasOwnProperty(pdk)) continue;
    url.searchParams.append(pdk, pd[pdk]);
  }
  return fetchParse(method, `${url.href}`, data);
};

function logRequestResponse(r, method, path, data, init) {
  const resultSeverity = r.res.ok ? Log.styles.info : Log.styles.error;
  const resultErrorText = r.res.statusText ? [r.res.status.toString(), `(${r.res.statusText})`] : [r.res.status.toString()];
  Log.g.collapsed("%c API %c %c %s ", resultSeverity, "", Log.styles.API[method], method, path.substring(env.api_path.length),
    ...(r.res.ok ? [] : resultErrorText));
  Log.l("time:", new Date().toISOString());
  Log.g.open("request");
  Object.keys(init).forEach(k => {
    if (k === "body") {
      Log.l("body:", data);
    } else if (k === "headers" && init[k]) {
      const headers: HeadersInit = init[k]!;
      Log.g.open("headers");
      Object.keys(headers).forEach(h => {
        Log.l(`${h}:`, headers[h]);
      });
      Log.g.close();
    } else Log.l(`${k}:`, init[k]);
  });
  Log.g.close();
  Log.g.open("response");
  ["ok", "status", "statusText", "type", "url", "redirected"].forEach(k => Log.l(`${k}:`, r.res[k]));
  Log.g.open("headers");
  [...r.res.headers.entries()].forEach(h => {
    Log.l(`${h[0]}: ${h[1]}`);
  });
  Log.g.close();
  Log.l("body:", r.out);
  Log.g.close();
  Log.g.collapsed("trace");
  console.trace();
  Log.g.close();
  Log.g.collapsed("log");
  const responseString = typeof r.out === "object" ? JSON.stringify(r.out, null, 2) : r.out;
  const uriPath = path.substring(env.api_path.length);
  const resStatusString = r.res.status.toString();

  if (data) {
    const dataString = JSON.stringify(data, null, 2);
    if (!r.out) {
      Log.l(`request \`${method} ${uriPath}\` ${resStatusString}:\n\`\`\`json\n${dataString}\n\`\`\`\n`);
    } else {
      const responseText = `response:\n\`\`\`json\n${responseString}\n\`\`\``;
      Log.l(`request \`${method} ${uriPath}\` ${resStatusString}:\n\`\`\`json\n${dataString}\n\`\`\`\n${responseText}`);
    }
  } else {
    Log.l(`response of \`${method} ${uriPath}\` ${resStatusString}:\n\`\`\`json\n${responseString}\n\`\`\``);
  }
  Log.g.close();
  Log.g.close();
}

export const fetchParse = (m: string, p: string, d?: any) => (async (method, path, data) => {
  const abortCtrl = new AbortController();
  const abortTimeout = setTimeout(() => {
    abortCtrl.abort();
    Log.l("%c API %c %c %s ", Log.styles.error, "", Log.styles.API[method], method,
      path.substring(env.api_path.length), "Aborted (timeout)");
    dispatchAlert(Severity.ERROR, "Couldn't connect to server!");
  }, fetchTimeout);

  let init: RequestInit = {method: method, credentials: "include", signal: abortCtrl.signal};
  if (data) init = {...init, body: data, headers: {...init.headers, "Content-Type": "application/json"}};

  let result;
  try {
    result = await fetch(path, init).then(parseRes);
  } finally {
    clearTimeout(abortTimeout);
  }

  if (env.api_path || !result.res.ok) {
    logRequestResponse(result, method, path, data, init)
  }
  if (!result.res.ok) throw new StatusError(result.res.status);
  return result.out;
})(m.toUpperCase(), endpoint(p), JSON.stringify(d));
