import React from "react";
import {ls} from "./locales";
import {env} from "./Environment";

export const setTitle = name => document.title = `ezRoute Console - ${name}`;

// @ts-ignore
export const childrenToString = children => React.Children.toArray(children).reduce((p, c) => p + (typeof c === "string" && p.trim() !== p ? c.trim() : c), '');

export const classMap = (...classes: (any)[]): string|undefined => {
  let out = "";
  for (const c of classes)
    if (Array.isArray(c)) out = classMap(out, ...c) || "";
    else if (c) out += " " + c;
  return out.trim() || undefined;
};

export const trim = (input: string, options: {chars?: string, trimLeft?: boolean, trimRight?: boolean} = {}): string => {
  const {chars = " \t\n", trimLeft: left = true, trimRight: right = true} = options;

  let start = 0,
    end = input.length;

  if (left) {
    while (start < end && chars.includes(input[start]))
      start++;
  }

  if (right) {
    while (start < end && chars.includes(input[end]))
      end--;
  }

  return input.substring(start, end);
};

export const trimLeft = (input: string, chars: string = " \t\n") => trim(input, {chars: chars, trimRight: false, trimLeft: true});
export const trimRight = (input: string, chars: string = " \t\n") => trim(input, {chars: chars, trimRight: true, trimLeft: false});

interface ILog {
  g: {
    open(...args),
    collapsed(...args),
    close()
  },
  d(...args),
  l(...args),
  e(...args),
  styles: {
    info: string, error: string, utils: string,
    API: {
      POST: string, PUT: string, GET: string, DELETE: string
    }
  }
}

const Log: ILog = {
  g: {
    open: console.group,
    collapsed: console.groupCollapsed,
    close: console.groupEnd
  },
  d(...args) {
    if (env.debug) console.log(...args);
  },
  l: console.log,
  e: console.error,
  styles: {
    info:  "background-color: #007BFF; color: white",
    error: "background-color: #F44336; color: white",
    utils: "background-color: #63942D; color: white",
    API: {
      POST:   "font-weight: normal; background-color: #212121; color: #FFEE58",
      PUT:    "font-weight: normal; background-color: #212121; color: #4A90E2",
      GET:    "font-weight: normal; background-color: #212121; color: #7ED321",
      DELETE: "font-weight: normal; background-color: #212121; color: #F44336"
    }
  }
};
export {Log};

// Map utils
type Crd = [number, number]
export const getPolylineCentreAndSize = (polyline: Crd[]): {center: Crd, size: Crd} => {
  const extremes = {min: [1000, 1000], max: [-1000, -1000]};
  polyline.forEach(p => p.forEach((v, i) => {
    extremes.min[i] = Math.min(extremes.min[i], v);
    extremes.max[i] = Math.max(extremes.max[i], v);
  }));
  return {
    center: [(extremes.min[0] + extremes.max[0]) / 2, (extremes.min[1] + extremes.max[1]) / 2],
    size: [extremes.max[0] - extremes.min[0], extremes.max[1] - extremes.min[1]]
  }
};

const zPad = (strings, ...exps): string => {
  let out = "";
  strings.forEach((v, i) => {
    out += v;
    if (exps[i] !== undefined)
      out += i ? exps[i].toString().padStart(2, "0") : exps[i];
  });
  return out;
};
export const formatTime = (s: number|undefined): string => {
  if (typeof s == "undefined") return ls("vouchers.new.routes.details.unknown");
  const h = s / 3600 << 0;
  const m = (s / 60) % 60 << 0;
  s %= 60;
  if (h) return zPad`${h}:${m}:${s}`;
  return zPad`${m}:${s}`;
}
export const formatDistance = (m: number|undefined): string => {
  if (typeof m == "undefined") return ls("vouchers.new.routes.details.unknown");
  if (m < 1000) return `${m} m`;
  return `${(m/1000).toFixed(2)} km`
}

export const equalsDateDay = (a: Date, b: Date): boolean => {
  if (a.getFullYear() !== b.getFullYear()) return false;
  if (a.getMonth() !== b.getMonth()) return false;
  return a.getDate() === b.getDate();
}

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type Merge<M, N> = Omit<M, Extract<keyof M, keyof N>> & N;
