import React from 'react';
import dateFormat from "dateformat";

import ModalInput from "./ModalInput";
import DropdownInput from "./DropdownInput";
import Calendar, {CalendarData} from "./Calendar";

import {Icon, Icons} from "../Icon";
import {helper} from "../BP";

import {classMap} from "../../Utils/utils";

import "./DateInput.scss";


const dateMask = "dd/mm/yyyy";
const selectMap = [{from: 0, to: 2, what: "day", max: 31}, {from: 3, to: 5, what: "month", max: 12}, {from: 6, to: 10, what: "year", max: 9999}];

interface Props {
  className?: string,
  onChange(v),
  value: Date,
  data?: CalendarData
}
interface State {
  text: string
}

const keyMap = {
  "ArrowUp": +1,
  "ArrowDown": -1,
  "ArrowRight": +1,
  "ArrowLeft": -1
};

export class DateInput extends React.Component<Props, State> {
  state = {
    text: "",
  };
  selected = -1;
  open = false;
  input: HTMLInputElement|null = null;

  constructor(props) {
    super(props);
    this.state.text = dateFormat(this.props.value, dateMask)
  }

  onFocus = () => {
    if (!this.input || document.activeElement !== this.input) return;
    if (helper.on("xs")) {
      this.input.blur();
      return;
    }
    let ss = this.input.selectionStart || 0;
    for (const i in selectMap) {
      const v = selectMap[i];
      if (ss >= v.from && ss <= v.to) {
        console.log(i);
        const dae = document.activeElement as HTMLInputElement;
        dae.selectionStart = v.from;
        dae.selectionEnd = v.to;
        this.selected = parseInt(i);
        break;
      }
    }
  };
  onBlur = () => {
    this.selected = -1;
  };

  onOpen = () => {
    if (!this.input) return;
    this.open = true;
    this.input.readOnly = true;
    this.selected = -1;
    const dae = document.activeElement as HTMLInputElement;
    dae.selectionStart = dae.selectionEnd = 0;
  };
  onClose = () => {
    if (!this.input) return;
    this.open = false;
    this.input.readOnly = false;
    this.onFocus();
  };

  keyDown = e => {
    const {key} = e;
    if (this.open) {
      let date = new Date(this.props.value.valueOf());
      const map = {
        x: n => date.setDate(date.getDate() + n),
        y: n => date.setDate(date.getDate() + 7*n),
        z: n => date.setMonth(date.getMonth() + n)
      };
      switch (key) {
        case "ArrowUp": {
          e.preventDefault();
          map.y(-1);
          break;
        }
        case "ArrowDown": {
          e.preventDefault();
          map.y(+1);
          break;
        }
        case "ArrowLeft": {
          e.preventDefault();
          map.x(-1);
          break;
        }
        case "ArrowRight": {
          e.preventDefault();
          map.x(+1);
          break;
        }
        case "PageUp": {
          e.preventDefault();
          map.z(-1);
          break;
        }
        case "PageDown": {
          e.preventDefault();
          map.z(+1);
          break;
        }
        default:
      }
      this.calendarChange(date);
    } else {
      let curr = selectMap[this.selected];
      if (!curr) return;
      let date = new Date(this.props.value.valueOf()),
        b = false;

      const map = [
        n => date.setDate(date.getDate() + n),
        n => date.setMonth(date.getMonth() + n),
        n => date.setFullYear(date.getFullYear() + n)
      ];
      const canMove = this.selected > 0 && this.selected < selectMap.length - 1;

      switch (key) {
        case "ArrowUp":
        case "ArrowDown":
          e.preventDefault();
          map[this.selected](keyMap[key]);
          break;
        case "ArrowLeft":
        case "ArrowRight":
          e.preventDefault();
          if (canMove) break;
          this.selected += keyMap[key];
          curr = selectMap[this.selected];
          b = !!curr;
          break;
        default:
      }
      if (!b) this.calendarChange(date);

      setTimeout(() => {
        const dae = document.activeElement as HTMLInputElement;
        dae.selectionStart = curr.from;
        dae.selectionEnd = curr.to;
      });
    }
  };

  calendarChange = v => {
    this.setState({text: dateFormat(v, dateMask)}, () => this.props.onChange(v));
  };

  render() {
    const {className, value, data} = this.props;
    const {text} = this.state;
    const props = {
      value: text,
      className: classMap("date-input", className),
      ref: r => this.input = r,
      keyDown: this.keyDown,
      afterInput: <Icon name={Icons.Calendar}/>,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onOpen: this.onOpen,
      onClose: this.onClose,
      children: <Calendar onChange={this.calendarChange} value={value} className="body" scrollable disableRed data={data} swipeSupport/>,
      onChange: () => {/* default */}
    };
    if (helper.on("xs")) return <ModalInput {...props}/>;
    return <DropdownInput {...props}/>;
  }
}