import React from "react";

import {Icon, Icons} from "../Icon";

import {ls} from "../../Utils/locales";
import {doubleClickLength} from "../../Utils/static";

import "./EditName.scss";


interface Props {
  value: string
  onChange(v: string): any
}

interface State {
  tValue: string
  editing: boolean
}

export default class EditName extends React.Component<Props, State> {
  state: State = {
    tValue: "",
    editing: false
  };
  lastClick = -1;


  onClick = e => {
    if (this.lastClick !== -1){
      if (e.currentTarget !== document) this.editStart();
      else this.lastClick = -1;
    }

    document.addEventListener("click", this.onClick);
    setTimeout(() => {
      this.lastClick = -1;
      document.removeEventListener("click", this.onClick);
    }, doubleClickLength);
    this.lastClick = Date.now();
  };

  editStart = () => {
    this.setState({editing: true, tValue: this.props.value});
  };
  editEnd = () => {
    this.setState({editing: false});
    this.props.onChange(this.state.tValue);
  };
  editCancel = () => {
    this.setState({editing: false});
  };

  onEditChange = ({target: {value}}) => {
    this.setState({tValue: value});
  };

  onEditKey = ({key}) => {
    switch (key) {
      case "Enter":
        this.editEnd();
        break;
      case "Escape":
        this.editCancel();
        break;
    }
  };

  render() {
    const {value} = this.props;
    const {editing, tValue} = this.state;
    return <div className="edit-name">
      {editing ?
        <input value={tValue} onChange={this.onEditChange} autoFocus onBlur={this.editCancel} onKeyDown={this.onEditKey}/> :
        <span onClick={this.onClick} className="__edit-span" title={ls("misc.double-click-edit")}>{value}</span>}
      <Icon name={Icons.Edit} onClick={this.editStart}/>
    </div>;
  }
}