import React from "react";

import {classMap} from "../../Utils/utils";

import "./TimePicker.scss";


enum ClockState {
  HOURS, MINUTES
}

const clockTexts = {
  [ClockState.HOURS  ]: [
    ["12",  "1",  "2",  "3",  "4",  "5",  "6",  "7",  "8",  "9", "10", "11", ],
    ["00", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", ],
  ],
  [ClockState.MINUTES]: [new Array(60).fill(0).map((v, k) => k.toString().padStart(2, "0"))]
};
const clockFaceProps = [{offset: 120, class: "outer"}, {offset: 75, class: "inner"}];


interface Props {
  onChange(v: string): any
  initialValue?: string
  size?: string
  className?: string
}

interface State {
  state: ClockState
  selected: {[x in ClockState]: string}
}

export default class TimePicker extends React.Component<Props, State> {
  state: State = {
    state: ClockState.HOURS,
    selected: {
      [ClockState.HOURS]: "--",
      [ClockState.MINUTES]: "--"
    }
  };
  clockRef: null|HTMLDivElement = null;


  updateFromProps = () => {
    const value = this.props.initialValue;
    if (!value || !/^\d\d:\d\d$/.test(value)) {
      throw new EvalError("bad initial value, should follow \"hh:mm\"");
    }
    const time = value.split(":");
    this.setState({selected: {
      [ClockState.HOURS]: time[0],
      [ClockState.MINUTES]: time[1]
    }});
  }

  constructor(props) {
    super(props);
    if (props.initialValue) {
      this.updateFromProps();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.initialValue !== this.props.initialValue) this.updateFromProps()
  }


  checkToUpdate = () => {
    const {selected} = this.state;
    if (selected[ClockState.HOURS].includes("-") || selected[ClockState.MINUTES].includes("-")) return;
    this.props.onChange(`${selected[ClockState.HOURS]}:${selected[ClockState.MINUTES]}`);
  };


  handleMovementStart = () => {
    this.clockRef!.classList.add("suspended");
  };
  handleMovementMove = ({clientX, clientY}) => {
    const {state} = this.state;
    const rect = this.clockRef!.getBoundingClientRect();
    const relativePos = [(rect.x + rect.width/2) - clientX, clientY - (rect.y + rect.height/2)];
    const calc = ((a) => Math.round(Math.atan2(relativePos[0], relativePos[1])*a/Math.PI + a) % (2*a));
    const pointed = calc({[ClockState.MINUTES]: 30, [ClockState.HOURS]: 6}[state]);
    const dist2 = relativePos[0] ** 2 + relativePos[1] ** 2;
    this.setState(s => ({selected: {...s.selected, [state]: clockTexts[state][state === ClockState.HOURS && dist2 < 6400 ? 1 : 0][pointed]}}));
  };
  handleMovementEnd = () => {
    this.clockRef!.classList.remove("suspended");
    this.setState(s => ({state: s.state === ClockState.HOURS ? ClockState.MINUTES : ClockState.HOURS}), this.checkToUpdate);
  };

  handleMovement = (e: React.MouseEvent|MouseEvent) => {
    if (!this.clockRef) return;
    const {type} = e;
    switch (type) {
      case "mousedown":
        window.addEventListener("mouseup", this.handleMovement);
        window.addEventListener("mousemove", this.handleMovement);
        this.handleMovementStart();
        // fallthrough
      case "mousemove":
        this.handleMovementMove(e);
        break;
      case "mouseup":
        window.removeEventListener("mouseup", this.handleMovement);
        window.removeEventListener("mousemove", this.handleMovement);
        this.handleMovementEnd();
        break;
    }
  };
  handleTouchMovement = (e: React.TouchEvent|TouchEvent) => {
    if (!this.clockRef) return;
    const {type, touches} = e;
    if (touches.length > 1) return;
    console.log(e.type);
    switch (type) {
      case "touchstart":
        window.addEventListener("touchend", this.handleTouchMovement);
        window.addEventListener("touchcancel", this.handleTouchMovement);
        window.addEventListener("touchmove", this.handleTouchMovement);
        this.handleMovementStart();
      // fallthrough
      case "touchmove":
        this.handleMovementMove(e.touches[0]);
        break;
      case "touchend":
      case "touchcancel":
        e.preventDefault();
        window.removeEventListener("touchend", this.handleTouchMovement);
        window.removeEventListener("touchcancel", this.handleTouchMovement);
        window.removeEventListener("touchmove", this.handleTouchMovement);
        this.handleMovementEnd();
        break;
    }
  };

  keyboardFresh = false;
  headerRefs: {[x in ClockState]: HTMLSpanElement|null} = {[ClockState.HOURS]: null, [ClockState.MINUTES]: null};
  onHeaderFocus = (which: ClockState) => () => {
    this.setState({state: which});
    this.keyboardFresh = true;
  };
  onHeaderBlur = (which: ClockState) => () => {
    const val = this.state.selected[which];
    if (val !== "--" && val.endsWith("-")) {
      this.setState(s => ({selected: {...s.selected, [which]: `0${val.substr(0, 1)}`}}), this.checkToUpdate);
    } else this.checkToUpdate();
  };
  onHeaderKeyDown = (which: ClockState) => (e: React.KeyboardEvent) => {
    const val = this.state.selected[which];
    const inverseWhich = which === ClockState.HOURS ? ClockState.MINUTES : ClockState.HOURS;
    if (/\d/.test(e.key)) {
      const k = parseInt(e.key);
      if (this.keyboardFresh) {
        if ((which === ClockState.HOURS && k <= 2) || (which === ClockState.MINUTES && k <= 5)) {
          this.setState(s => ({selected: {...s.selected, [which]: `${k}-`}}));
          this.keyboardFresh = false;
        } else {
          this.setState(s => ({selected: {...s.selected, [which]: `0${k}`}}), () =>
            this.headerRefs[inverseWhich]?.focus()
          );
        }
      } else {
        console.log("keydown", val, k);
        this.setState(s => ({selected: {...s.selected, [which]: `${val.substr(0, 1)}${k}`}}), () =>
          this.headerRefs[inverseWhich]?.focus()
        );
      }
    }
  };


  render() {
    const {state, selected} = this.state;
    return <div className={classMap("time-picker", this.props.size, this.props.className)}>
      <div className="header">
        <span tabIndex={0} onFocus={this.onHeaderFocus(ClockState.HOURS)} onBlur={this.onHeaderBlur(ClockState.HOURS)}
              onKeyDown={this.onHeaderKeyDown(ClockState.HOURS)} ref={r => this.headerRefs[ClockState.HOURS] = r}
              className={classMap(state === ClockState.HOURS   && "active")}>{selected[ClockState.HOURS].padStart(2, "0")}</span>
        :
        <span tabIndex={0} onFocus={this.onHeaderFocus(ClockState.MINUTES)} onBlur={this.onHeaderBlur(ClockState.MINUTES)}
              onKeyDown={this.onHeaderKeyDown(ClockState.MINUTES)} ref={r => this.headerRefs[ClockState.MINUTES] = r}
              className={classMap(state === ClockState.MINUTES && "active")}>{selected[ClockState.MINUTES]}</span>
      </div>
      <div className="cont" ref={r => this.clockRef = r} onMouseDown={this.handleMovement} onTouchStart={this.handleTouchMovement}>
        <svg className="hand" viewBox="-150 -150 300 300">
          <g transform={{
            [ClockState.HOURS  ]: `rotate(${parseInt(selected[ClockState.HOURS  ]) * 30 || 0})`,
            [ClockState.MINUTES]: `rotate(${parseInt(selected[ClockState.MINUTES]) * 6  || 0})`
          }[state]}>
            {(() => {
              if (selected[state] === "--") return <circle r="4"/>;
              const inner = state === ClockState.HOURS && clockTexts[ClockState.HOURS][1].indexOf(selected[ClockState.HOURS]) > -1;
              const dist = clockFaceProps[inner ? 1 : 0].offset;
              const outerRadius = state === ClockState.MINUTES && parseInt(selected[ClockState.MINUTES]) % 5 !== 0 ? "12" : "20"
              return <>
                <circle r="4"/>
                <line y2={-dist} stroke="black" strokeWidth="2"/>
                <circle cy={-dist} r={inner ? "16" : outerRadius}/>
              </>
            })()}
          </g>
        </svg>
        <div className="face">
          {clockTexts[state].map((clockTextRow, textRowNr) => {
            const cfp = clockFaceProps[textRowNr];
            const translate = index => {
              const angle = index * Math.PI / 6;
              const vector = [Math.sin(angle), -Math.cos(angle)].map((v, k) => (v * cfp.offset + k * 150).toFixed(2) + "px");
              return `translate(${vector[0]}, ${vector[1]}) translate(-50%, -50%)`;
            };
            if (state === ClockState.MINUTES) clockTextRow = clockTextRow.filter((_, k) => k % 5 === 0);
            return <div key={textRowNr} className={cfp.class}>
              {clockTextRow.map((clockText, clockTextNr) =>
                <span key={clockTextNr} style={{transform: translate(clockTextNr)}} className={classMap(clockText === selected[state] && "active")}>{clockText}</span>
              )}
            </div>;
          })}
        </div>
      </div>
      {/*<div className="footer">
        <span className="t-btn empty"/>
        <span className="t-btn">Cancel</span>
        <span className="t-btn">OK</span>
      </div>*/}
    </div>;
  }
}