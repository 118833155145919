import {withContext} from "../../Components/AppContext";
import {API, Car} from "../../Utils/APIShapes";

import EditableList, {EditableListProps, EditableListState, ItemPropType} from "./EditableList";


interface State extends EditableListState<Car> {
  items: Car[]
}

class Cars extends EditableList<Car, EditableListProps, State> {
  state: State = {
    activeModal: -1,
    modalOpen: false,
    items: [],
    alertState: 0,
    loading: true,
    choicesLoading: {driver: true},
    choices: {}
  };

  constructor(props) {
    super(props,
      "car", "titles.cars",
      [
        {name: "nrPlate", locale: "number"},
        {name: "carType", locale: "type"},
        "model",
        {name: "fuel", type: ItemPropType.NUMBER},
        {name: "fuelCons", locale: "consumption", type: ItemPropType.NUMBER, showInList: false},
        {name: "odometer", type: ItemPropType.NUMBER, showInList: false},
        {name: "preferedDriver", locale: "pref-driver", type: ItemPropType.DRIVER, showInList: false, required: false,
          getValue: (v) => v.preferedDriver?.id}
      ], {get: API.get.cars, del: API.del.car, post: API.post.car, put: API.put.car});
  }

  blankItem = () => ({fuelCons: 0, carType: "", fuel: 0, model: "", nrPlate: "", odometer: 0});
}

export default withContext(Cars);
