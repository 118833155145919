import React, {Component} from 'react';
import SmoothCollapse from 'react-smooth-collapse';

import {Icon, Icons} from "./Icon";

import {l} from "../Utils/locales";
import {classMap} from "../Utils/utils";

import './CollapseCard.scss';


interface Props {
  className?: string
  collapsed?: boolean
  title?: string|JSX.Element
  onCollapse?()
  headerCollapse?: boolean
}
interface State {
  collapsed: boolean
}

class CollapseCard extends Component<Props, State> {
  state = {
    collapsed: this.props.collapsed || false
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.props.collapsed !== prevProps.collapsed)
      this.setState({collapsed: this.props.collapsed || false});
  }

  collapse = () => {
    this.setState(s => ({collapsed: !s.collapsed}), this.props.onCollapse || undefined);
  };

  render() {
    const {className, title, children, headerCollapse} = this.props;
    const {collapsed} = this.state;
    return <div className={classMap("v-card", className)}>
      <div className={classMap("header", collapsed && "collapsed")} onClick={headerCollapse ? this.collapse : undefined}>
        {title}
        <span className="collapser" onClick={headerCollapse ? undefined : this.collapse}>
          {collapsed ? l("collapse-card.expand") : l("collapse-card.collapse")}
          <Icon name={Icons.ArrowDown}/>
        </span>
      </div>
      <SmoothCollapse className={"c-body" + (collapsed ? " collapsed" : "")} expanded={!collapsed} allowOverflowWhenOpen>{children}</SmoothCollapse>
    </div>
  }
}

export default CollapseCard;