export let env = {
  debug: false,
  api_path: "http://localhost:5000",
  map_url: "",
  base_path: "/",
  base_url: "?",
};

export async function loadEnv() {
  env = await fetch(`${process.env.PUBLIC_URL}/env.json`).then(res => res.json());

  let local = process.env.PUBLIC_URL;
  if (local.startsWith(".")) local = local.substring(1, local.length);
  if (local.startsWith("/")) local = local.substring(1, local.length);
  let base = env.base_path;
  if (!base.endsWith("/")) base += "/";
  env.base_url = base + local;
  if (env.base_url.endsWith("/")) env.base_url = env.base_url.substring(0, env.base_url.length - 1);
  return env.base_url;
}
