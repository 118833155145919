import React, {ReactNode} from 'react';

import "./Loading.scss";


const circles: ReactNode[] = [];
for (let i = 0; i < 8; i++) {
  const a = i*(Math.PI/4);
  circles.push(<circle key={i} r={2} cx={(Math.sin(a) * 6).toFixed(2)} cy={(Math.cos(a) * 6).toFixed(2)}/>)
}

class Loading extends React.Component {
  timer: any;

  render() {
    return <div className="loading" aria-label="Loading">
      <svg viewBox="-8 -8 16 16">
        {circles}
      </svg>
    </div>;
  }
}

export default Loading;