import React, {Component} from "react";
import * as Scroll from "react-scroll";
import {Alert, Button, Col, CustomInput, FormGroup, Input, Label, Row} from "reactstrap";

import Route from "./Route";

import {IAppContext} from "../App";

import BP from "../../Components/BP";
import CustomSelect from "../../Components/Inputs/CustomSelect";
import CollapseCard from "../../Components/CollapseCard";
import {withContext} from "../../Components/AppContext";
import Calendar, {CalendarData, dateFormat} from "../../Components/Inputs/Calendar";

import {API, Car, Driver, Route as APIRoute, Voucher, VoucherWithoutID} from "../../Utils/APIShapes";
import {l, ls} from "../../Utils/locales";
import {equalsDateDay, formatDistance, formatTime, Log, setTitle} from "../../Utils/utils";

import "./Vouchers.scss";
import {dispatchAlertFromError} from "../../Components/Alerts";
import {env} from "../../Utils/Environment";

const returningBalanceCalculation = (data, totals) => {
  return  data.startingFuel !== undefined && totals.full.dist > 0 && data.car && data.car.fuelCons !== undefined ?
    data.startingFuel + totals.fuelIssued - totals.full.dist / 1000 * data.car.fuelCons / 100 : (data.startingFuel || undefined);
};
const getName = (value) => (value || {}).name;
const calculateCommonDataFields = (data, totals, userCompany) => {
  const returningBalanceCalc = returningBalanceCalculation(data, totals);
  return {
    organisation: getName(data.company) || getName(userCompany) || "-",
    driver: data.driver ? data.driver.id : 0,
    car: data.car ? data.car.id : 0,

    fuelIssued: totals.fuelIssued.toFixed(2),
    fuelLeaving: data.startingFuel !== undefined ? data.startingFuel.toFixed(2) : "-",
    fuelReturning: returningBalanceCalc !== undefined ? returningBalanceCalc.toFixed(2) : "-",
    fuelDifference: (data.fuelReturning !== undefined || returningBalanceCalc !== undefined) && data.startingFuel !== undefined ?
      (data.startingFuel - (data.fuelReturning || returningBalanceCalc || 0)).toFixed(2) : "-",
    fuelConsNormal: data.startingFuel !== undefined && data.car && data.car.fuelCons !== undefined ? data.car.fuelCons.toFixed(2) : "-",
    fuelConsActual: data.fuelReturning !== undefined && data.startingFuel !== undefined && totals.full.dist > 0 ?
      ((data.startingFuel - data.fuelReturning + (data.fuelIssued || 0)) / totals.full.dist * 1000).toFixed(2) : "-",
  }
};

// TODO reload on entry?

interface Props {
  context: IAppContext,
  match: any
}
interface State {
  data: Partial<Voucher>
  choices: {driver: Driver[], car: Car[]}
  addedRoutes: number[]
  open: {car: boolean, driver: boolean}
  alertState: number
  date: Date
  calendarData: CalendarData
  onlyToday: boolean
  loading: {data: boolean, car: boolean, driver: boolean}
  totals: {day: {time: number, dist: number}, full: {time: number, dist: number}, fuelIssued: number}
  problems: {driver: boolean, car: boolean, routes: number[]}
}

class VouchersEdit extends Component<Props, State> {
  state: State = {
    data: {routes: []},
    // data: {
    //   car: {id: 0, fuel: 0, fuelCons: 0, model: "", nrPlate: "", odometer: 0, profile: {name: ""}}, id: 0,
    //   company: {id: 0, location: {id: 0, w3wAddress: "", shortName: "", profile: {name: ""}, point: {lat: 0, lng: 0}, favorite: false, address: {id: 0, w3wAddress: "", point: {lat: 0, lng: 0}, fullAddress: "", country: {name: ""}, building: ""}}, regNr: "", profile: {name: ""}, name: "", active: false},
    //   date: 0, driver: {id: 0, name: "", surname: "", profile: {name: ""}}, factCons: 0, finished: false, fuelIssued: 0, fuelReturning: 0, name: "", routes: [], startingFuel: 0, userID: 0, voucherNr: 0},
    choices: {driver: [], car: []},
    addedRoutes: [],
    open: {car: false, driver: false},
    alertState: 0,
    date: new Date(),
    calendarData: {},
    onlyToday: false,
    loading: {data: false, car: true, driver: true},
    totals: {day: {time: 0, dist: 0}, full: {time: 0, dist: 0}, fuelIssued: 0},
    problems: {driver: false, car: false, routes: []}
  };
  routeCounter = 0;

  componentDidMount() {
    const {id} = this.props.match.params;
    if (id) {
      this.setState(s => ({loading: {...s.loading, data: true}}));
      const d = this.props.context.getData();
      if (d) this.setState(s => ({data: d, loading: {...s.loading, data: false}}));
      else API.get.voucher(id).then(v => v ? this.setState(s => ({data: v, loading: {...s.loading, data: false}})) :
        this.props.context.redirectTo("/vouchers/new")).catch(e => {
        Log.e(e);
        dispatchAlertFromError("Failed to fetch voucher!");
      });
    }
    API.get.cars({count: 50}).then(v => this.setState(s => ({choices: {...s.choices, car: v || []}, loading: {...s.loading, car: false}}))).catch(e => {
      Log.e(e);
      dispatchAlertFromError("Failed to fetch cars!");
    });
    API.get.drivers({count: 50}).then(v => this.setState(s => ({choices: {...s.choices, driver: v || []}, loading: {...s.loading, driver: false}}))).catch(e => {
      Log.e(e);
      dispatchAlertFromError("Failed to fetch drivers!");
    });
    this.props.context.setNavBar(true);
    setTitle(this.props.match.params.id ? l("vouchers.new.title.edit") : l("vouchers.new.title.new"));
  }

  toggle = what => () => this.setState(s => ({open: {...s.open, [what]: !s.open[what]}}));
  save = async (what, value) => {
    if (!['car', 'driver'].includes(what)) return;
    switch (what) {
      case "car": {
        try {
          await API.post.car(value);
          const cars = await API.get.cars({count: 50});
          this.setState(s => ({alertState: 3, choices: {...s.choices, car: cars}}), Scroll.animateScroll.scrollToTop);
        } catch (e) {
          Log.e(e);
          this.setState({alertState: -3}, Scroll.animateScroll.scrollToTop);
        }
        break;
      }
      case "driver": {
        try {
          await API.post.driver(value);
          const drivers = await API.get.drivers({count: 50});
          this.setState(s => ({alertState: 2, choices: {...s.choices, driver: drivers}}), Scroll.animateScroll.scrollToTop);
          break;
        } catch (e) {
          Log.e(e);
          this.setState({alertState: -2}, Scroll.animateScroll.scrollToTop);
        }
      }
    }
    this.toggle(what)();
  };

  selectorChange = (what: string) => (id: number) => {
    if (!["driver", "car"].includes(what)) return;
    const ref = this.state.choices[what].filter(v => v.id === id)[0];
    Log.d(ref);
    if (what === "car") {
      this.setState(s => {
        const data = {...s.data, startingFuel: ref.fuel, car: ref};
        if (!this.state.data.driver && ref.preferedDriver) {
          data.driver = ref.preferedDriver;
        }
        return ({data: data, problems: {...s.problems, car: false}});
      }, this.recalculateTotals);
    } else this.setState(s => ({data: {...s.data, driver: ref}, problems: {...s.problems, driver: false}}));
  }

  submit = draft => () => {
    const {redirectTo, userCompany, userData} = this.props.context;
    const {date, data, totals} = this.state;
    const {company, driver, car, startingFuel, id, routes} = data;
    const {fuelIssued, fuelReturning} = calculateCommonDataFields(data, totals, userCompany);

    const problemRoutes = routes ? routes.filter(r => !r.duration).map(r => r.id) : [];
    if (!driver || !car || typeof fuelIssued === "undefined" || typeof startingFuel === "undefined" || typeof fuelReturning === "undefined") {
      Log.d(!!driver, !!car, typeof fuelIssued, typeof startingFuel, typeof fuelReturning)
      this.setState({alertState: -1, problems: {driver: !driver, car: !car, routes: problemRoutes}}, Scroll.animateScroll.scrollToTop);
      return;
    }
    if (!userCompany || !userData) throw new Error("user company/data is not defined!!!!");
    const voucher: VoucherWithoutID = {
      voucherNr: "", factCons: 0,
      routes: data.routes, name: data.name || "", userID: data.userID || userData.id,
      date: date.getTime(), finished: !draft,
      driver: driver, car: car, company: company || userCompany,
      fuelIssued: fuelIssued, startingFuel: startingFuel, fuelReturning: fuelReturning
    };
    Log.d(voucher);
    const refresh = which => {
      this.setState(s => ({alertState: 1, loading: {...s.loading, data: true}}), Scroll.animateScroll.scrollToTop);
      API.get.voucher(which).then(v => v ? this.setState(s => ({data: v, loading: {...s.loading, data: false}})) :
        redirectTo("/vouchers/new")).catch(e => {
          Log.e(e);
          dispatchAlertFromError("Failed to fetch voucher!");
        });
    };
    if (id) {
      API.put.voucher(id, {...voucher, id: id})
        .then(() => refresh(id))
        .catch(() => this.setState({alertState: -1}, Scroll.animateScroll.scrollToTop));
    } else {
      API.post.voucher(voucher)
        .then(({id: which}) => {
          redirectTo(`/vouchers/edit/${which}`);
          refresh(which);
        })
        .catch(() => this.setState({alertState: -1}, Scroll.animateScroll.scrollToTop));
    }
    window.removeEventListener("beforeunload", this.beforeUnload);
  };

  calendarToggle = ({target: {checked}}) => {
    this.setState(s => ({calendarData: {...s.calendarData, [dateFormat(s.date)]: (checked ? 0 : -1)}}), this.checkCalendarData);
  };
  checkCalendarData = () => this.setState(s => {
    Object.keys(s.calendarData).forEach(v => {
      if (s.calendarData[v] > 0) s.calendarData[v] = 0;
    });
    s.data.routes?.forEach(({date, waypoints}) => {
      const df = dateFormat(new Date(date || 0)), d = s.calendarData[df];
      if (waypoints?.some(v => v.refueling)) s.calendarData[df] = 2;
      else if (!d) s.calendarData[df] = 1;
    });
    window.addEventListener("beforeunload", this.beforeUnload);
    return s;
  }, this.recalculateTotals);

  addRoute = e => {
    e.preventDefault();
    const added = this.state.addedRoutes;
    if (!this.state.data.routes) {
      this.setState(s => ({data: {...s.data, routes: []}}))
      this.addRoute(e);
      return;
    }
    added.push(this.state.data.routes.push({
      waypoints: [], date: new Date(this.state.date).getTime(),
      name: ls("vouchers.new.routes.card-title", ++this.routeCounter),
      duration: 0, distance: 0, id: 0
    }) - 1);
    this.setState({data: this.state.data, addedRoutes: added}, this.checkCalendarData);
  };

  onRouteChange = k => (n: APIRoute, cb?: () => any) => this.setState(s => {
    s.data.routes![k] = {...n};
    if (n.duration && s.problems.routes.includes(n.id)) s.problems.routes = s.problems.routes.filter(v => v !== n.id);
    return s;
  }, () => {
    if (cb) cb();
    this.checkCalendarData()
  });
  routeRemove = k => () => this.setState(s => {
    s.data.routes?.splice(k, 1);
    return s;
  }, this.checkCalendarData);

  beforeUnload = (e: BeforeUnloadEvent) => {
    if (env.api_path) return;
    e.preventDefault();
    e.returnValue = ls("vouchers.new.alert.unsaved-changes");
    return e.returnValue;
  };

  changeBalanceReturning = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;
    return this.setState(s => ({data: {...s.data, balanceReturning: value !== "" ? parseFloat(value) : undefined}}));
  }

  recalculateTotals = () => {
    const {totals, data: {routes}, date: currDate} = this.state;
    totals.day  = {dist: 0, time: 0};
    totals.full = {dist: 0, time: 0};
    totals.fuelIssued = 0;
    routes?.forEach(({distance = 0, duration = 0, date, waypoints}) => {
      totals.full.dist += distance;
      totals.full.time += duration;
      if (equalsDateDay(new Date(date || 0), currDate)) {
        totals.day.dist += distance;
        totals.day.time += duration;
      }
      totals.fuelIssued += waypoints?.filter(s => s.refueling).reduce((p, v) => p + (v.fuel || 0), 0) || 0;
    });

    this.setState({totals: totals});
  };

  render() {
    const {choices, alertState, onlyToday, data, calendarData, date, loading, totals, problems} = this.state;
    const {userCompany} = this.props.context;
    const routesL = data.routes?.filter((v) => typeof v !== "undefined" && (!onlyToday || (dateFormat(new Date(v.date || 0)) === dateFormat(date)))).length;
    const dataFields = calculateCommonDataFields(data, totals, userCompany);
    return <div>
      <div className="common">
        <>
          <Alert color="success" isOpen={alertState ===  1} toggle={() => this.setState({alertState: 0})}>{l("vouchers.new.alert.saved")}</Alert>
          <Alert color="danger"  isOpen={alertState === -1} toggle={() => this.setState({alertState: 0})}>{l("vouchers.new.alert.failed")}</Alert>
          <Alert color="success" isOpen={alertState ===  2} toggle={() => this.setState({alertState: 0})}>{l("vouchers.new.alert.driver.saved")}</Alert>
          <Alert color="danger"  isOpen={alertState === -2} toggle={() => this.setState({alertState: 0})}>{l("vouchers.new.alert.driver.failed")}</Alert>
          <Alert color="success" isOpen={alertState ===  3} toggle={() => this.setState({alertState: 0})}>{l("vouchers.new.alert.car.saved")}</Alert>
          <Alert color="danger"  isOpen={alertState === -3} toggle={() => this.setState({alertState: 0})}>{l("vouchers.new.alert.car.failed")}</Alert>
        </>
        <CollapseCard title={ls("vouchers.new.common.title")} collapsed={!!data.id} headerCollapse={true}>
          <Row>
            <Col sm>
              <FormGroup className="no-add">
                <Label for="voucher-organisation">{l("vouchers.new.common.organisation")}</Label>
                <Input disabled value={dataFields.organisation} id="voucher-organisation"/>
              </FormGroup>
              <FormGroup>
                <Label for="voucher-car">{l("vouchers.new.common.car")}</Label>
                <CustomSelect onChange={this.selectorChange("car")} value={dataFields.car} invalid={problems.car}
                              placeholder={ls("vouchers.new.common.select.placeholder.car")} id="voucher-car" searchable
                              emptyValue={ls("vouchers.new.common.select.empty.car")} loading={loading.car}>
                  {choices.car.map((v: Car) => <option key={v.id} value={v.id}>{v.nrPlate}</option>)}
                </CustomSelect>
              </FormGroup>
              <FormGroup>
                <Label for="voucher-driver">{l("vouchers.new.common.driver")}</Label>
                <CustomSelect onChange={this.selectorChange("driver")} value={dataFields.driver} invalid={problems.driver}
                              placeholder={ls("vouchers.new.common.select.placeholder.driver")} id="voucher-driver" searchable
                              emptyValue={ls("vouchers.new.common.select.empty.driver")} loading={loading.driver}>
                  {choices.driver.map((v: Driver) => <option key={v.id} value={v.id}>{v.name} {v.surname}</option>)}
                </CustomSelect>
              </FormGroup>
            </Col>
            <Col xs="auto" className="divider"/>
            <Col sm>
              <h4>{l("vouchers.new.common.fuel.title")}</h4>
              <Row>
                <Col><Label for="voucher-fuel-issued">{l("vouchers.new.common.fuel.issued")}</Label></Col>
                <Col><Input disabled value={dataFields.fuelIssued} id="voucher-fuel-issued"/></Col>
              </Row>
              <h5>{l("vouchers.new.common.fuel.balance")}</h5>
              <Row>
                <Col><Label for="voucher-fuel-leaving">{l("vouchers.new.common.fuel.leaving")}</Label></Col>
                <Col><Input disabled value={dataFields.fuelLeaving}
                            id="voucher-fuel-leaving"/></Col>
              </Row>
              <Row>
                <Col><Label for="voucher-fuel-returning">{l("vouchers.new.common.fuel.returning")}</Label></Col>
                <Col><Input disabled value={dataFields.fuelReturning}
                            id="voucher-fuel-returning"/></Col>
              </Row>
              <Row>
                <Col><Label for="voucher-fuel-correction">{l("vouchers.new.common.fuel.correction")}</Label></Col>
                <Col><Input type="number" value={data.fuelReturning !== undefined ? data.fuelReturning : ""}
                            onChange={this.changeBalanceReturning} id="voucher-fuel-correction"/></Col>
              </Row>
              <Row>
                <Col><Label for="voucher-fuel-difference">{l("vouchers.new.common.fuel.difference")}</Label></Col>
                <Col><Input disabled value={dataFields.fuelDifference} id="voucher-fuel-difference"/>
                </Col>
              </Row>
              <h5>{l("vouchers.new.common.fuel.consumption")}</h5>
              <Row className="consumption">
                <Col><Label for="voucher-fuel-normal">{l("vouchers.new.common.fuel.normal")}</Label></Col>
                <Col><Input disabled value={dataFields.fuelConsNormal}
                            id="voucher-fuel-normal"/></Col><Col>{l("vouchers.new.common.fuel.units")}</Col>
              </Row>
              <Row className="consumption">
                <Col><Label for="voucher-fuel-actual">{l("vouchers.new.common.fuel.actual")}</Label></Col>
                <Col><Input disabled value={dataFields.fuelConsActual} id="voucher-fuel-actual"/></Col>
                <Col>{l("vouchers.new.common.fuel.units")}</Col>
              </Row>
            </Col>
          </Row>
        </CollapseCard>
      </div>
      <Row>
        <div className="col-12 col-lg-10 col-xl-auto m-lg-auto m-xl-0 p-xl-0 d-flex justify-content-center">
          <div className="d-flex flex-column flex-md-row flex-xl-column justify-content-center justify-content-xl-start cal-card">
            <Calendar value={date} onChange={d => this.setState({date: d}, this.recalculateTotals)} data={calendarData}/>
            <CollapseCard title={ls("vouchers.new.calendar.title")} className="cal-extension">
              <Row>
                <Col><CustomInput type="switch" label={l("vouchers.new.calendar.enable-day")} id="enable-day"
                                  checked={calendarData[dateFormat(date)] !== -1} onChange={this.calendarToggle}/></Col>
                <Col><CustomInput type="switch" label={l("vouchers.new.calendar.only-today")} id="only-today"
                                  checked={onlyToday} onChange={() => this.setState(s => ({onlyToday: !s.onlyToday}))}/></Col>
              </Row>
              <Row>
                <Col><Label for="voucher-calendar-time">{l("vouchers.new.calendar.day-time")}</Label></Col>
                <Col><Input disabled value={formatTime(totals.day.time)} id="voucher-calendar-time"/></Col>
              </Row>
              <Row>
                <Col><Label for="voucher-calendar-dist">{l("vouchers.new.calendar.day-dist")}</Label></Col>
                <Col><Input disabled value={formatDistance(totals.day.dist)} id="voucher-calendar-dist"/></Col>
              </Row>
            </CollapseCard>
          </div>
        </div>
        <BP below="lg" className="w-100"/>
        <div className="routes col col-lg-10 col-xl">
          <h4>{l("vouchers.new.routes.title")}</h4>
          {routesL ? <div className="align-right"><a href="/" className="new-v-link" onClick={this.addRoute}>{l("vouchers.new.routes.add-new")}</a></div> : undefined}
          <div className="route-container">
            {routesL ? undefined : <div className="v-card route-cont route-placeholder" onClick={this.addRoute}><span className="new-v-link">{l("vouchers.new.routes.add-new")}</span></div>}
            {data.routes?.sort((a, b) => (a.date || 0) - (b.date || 0)).map((v, k) => {
              if (typeof v === "undefined") return undefined;
              if (this.state.onlyToday && dateFormat(new Date(v.date || 0)) !== dateFormat(this.state.date)) return undefined;
              return <Route key={k} data={v} onChange={this.onRouteChange(k)} remove={this.routeRemove(k)} invalid={problems.routes.includes(v.id)}
                            collapsed={!this.state.addedRoutes.includes(k)} calendarData={this.state.calendarData}/>
            })}
          </div>
          <div className="align-right"><a href="/" className="new-v-link" onClick={this.addRoute}>{l("vouchers.new.routes.add-new")}</a></div>
        </div>
      </Row>
      <div className="routes routes-end">
        <div className="spacer"/>
        <Row>
          <Col><Label for="voucher-total-time">{l("vouchers.new.total-time")}</Label></Col>
          <Col><Input disabled value={formatTime(totals.full.time)} id="voucher-total-time"/></Col>
        </Row>
        <Row>
          <Col><Label for="voucher-total-dist">{l("vouchers.new.total-dist")}</Label></Col>
          <Col><Input disabled value={formatDistance(totals.full.dist)} id="voucher-total-dist"/></Col>
        </Row>
        <Row className="btns">
          <Col sm><Button color="secondary" onClick={this.submit(false)}>{l("vouchers.new.save")}</Button></Col>
          <Col sm><Button color="success" onClick={this.submit(true)}>{l("vouchers.new.save-draft")}</Button></Col>
        </Row>
      </div>
    </div>;
  }
}

export default withContext(VouchersEdit);