import React, {Component} from "react";

import {Icon, Icons} from "../Icon";

import {classMap} from "../../Utils/utils";
import {formatMonth, la} from "../../Utils/locales";
import {animLength} from "../../Utils/static";

import "./Calendar.scss";


export const monthKey = (d: Date) => `${d.getFullYear()}-${d.getMonth()+1}`;
export const dateFormat = (d: Date) => `${monthKey(d)}-${d.getDate()}`;

const monthData = new Map();

export type CalendarData = {[x: string]: number};

interface Props {
  className?: string
  onChange(v: Date)
  value: Date
  scrollable?: boolean
  data?: CalendarData
  disableRed?: boolean
  swipeSupport?: boolean
}
interface State {
  animation: number|undefined,
  month: Day[][]
}

interface Day {
  date: number,
  month?: number,
  format?
}

class Calendar extends Component<Props, State> {
  state: State = {animation: undefined, month: []};
  counter = 0;
  touch: Touch|undefined = undefined;
  scroll: boolean = false;

  componentDidMount() {
    this.getMonthData(this.props.value);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && this.props && prevProps.value !== this.props.value && monthKey(prevProps.value) !== monthKey(this.props.value))
      this.getMonthData(this.props.value);
  }

  getMonthData = date => {
    const mk = monthKey(date);
    if (!monthData.has(mk)) {
      const out: Day[][] = [[]], lastMonth = new Date(date.getFullYear(), date.getMonth(), 0);
      const firstDay = lastMonth.getDay(), lastMonthLastDate = lastMonth.getDate();
      const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      const format = d =>`${date.getFullYear()}-${date.getMonth()+1}-${d}`;
      let week = 0;
      // Set previous month days
      for (let i = 0; i < firstDay; i++) {
        out[week][i] = {month: -1, date: lastMonthLastDate - firstDay + i + 1};
      }
      if (!firstDay) out[++week] = [];
      // Set current month days
      for (let i = 0; i < lastDate; i++) {
        out[week][(i + firstDay) % 7] = {date: i + 1, format: format(i + 1)};
        if ((i + firstDay) % 7 === 6) out[++week] = [];
      }
      // Set next month days
      for (let i = 0; (i + lastDate + firstDay - 1) % 7 !== 6; i++) {
        out[week][(i + lastDate + firstDay) % 7] = {month: +1, date: i + 1};
      }
      monthData.set(mk, out);
    }
    this.setState({month: monthData.get(mk)});
  };
  changeMonth = amount => {
    const {value, onChange = () => {/* do nothing */}} = this.props, prevMonthVal = value.getMonth();
    value.setMonth(prevMonthVal + amount);
    if ((value.getMonth() - prevMonthVal - amount) % 12 !== 0) value.setDate(0);
    this.setState({animation: Math.sign(amount)}, () => {
      this.getMonthData(value);
      setTimeout(() => this.setState({animation: undefined}), animLength + 50);
    });
    onChange(value);
  };

  addScroll = ref => {
    if (!ref || this.scroll) return;
    ref.addEventListener("wheel", e => {
      const {scrollable} = this.props;
      if (!scrollable) return;
      e.preventDefault();
      let v = this.counter || e.deltaX || -e.deltaY;
      if (Math.abs(v) <= 30) this.counter += v;
      else {
        this.counter = 0;
        this.changeMonth(Math.sign(v));
      }
    });
    this.scroll = true;
  };

  touchEvent = (touchEvent) => {
    const functions = {
      "touchstart": (touchStartEvt) => {
        if (touchStartEvt.touches.length > 1) functions.touchcancel();
        else this.touch = touchStartEvt.changedTouches[0];
      },
      "touchend": (touchEndEvt) => {
        if (!this.touch) return;
        const diff = {x: this.touch.screenX - touchEndEvt.changedTouches[0].screenX, y: this.touch.screenY - touchEndEvt.changedTouches[0].screenY};
        if (Math.abs(diff.x) > window.innerWidth / 3 && Math.abs(diff.y) < window.innerWidth / 4)
          this.changeMonth(Math.sign(diff.x));
        functions.touchcancel();
      },
      "touchcancel": () => {
        this.touch = undefined;
      }
    };
    functions[touchEvent.type](touchEvent);
  };

  render() {
    const {className, onChange = () => {/* do nothing */}, value, data = {}, swipeSupport} = this.props;
    const {animation, month} = this.state;
    let swiping = {};
    if (swipeSupport) swiping = {
      onTouchStart: this.touchEvent,
      onTouchEnd: this.touchEvent,
      onTouchCancel: this.touchEvent
    };
    return <div className={classMap("cal-body", className)} ref={this.addScroll}>
      <div className="header">
        <Icon name={Icons.ArrowLeft} onClick={() => this.changeMonth(-1)}/>
        <span>{formatMonth(value)}</span>
        <Icon name={Icons.ArrowRight} onClick={() => this.changeMonth(1)}/>
      </div>
      <div className="cal-row names">{la("calendar.days").map((v, k) => <span key={k}>{v}</span>)}</div>
      <div className={classMap(animation && `fade-in-${animation === 1 ? "left" : "right"}`)} {...swiping}>
        {month.map((days, weekNr) => <div className="cal-row" key={weekNr}>{days.map((day, dayNr) =>
          <span className={classMap(day.month && "gray", !day.month && day.date === value.getDate() && "today", {[-1]: "red", 1: "green", 2: ["green", "refuel"]}[data[day.format]])}
                key={dayNr} onClick={() => {
                  if (day.month) this.changeMonth(day.month);
                  value.setDate(day.date);
                  onChange(value);
                }}>{day.date}</span>
        )}</div>)}
      </div>
    </div>;
  }
}

export default Calendar;