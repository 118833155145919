import React, {Component} from "react";
import md5 from "md5";
import {pathToRegexp} from "path-to-regexp";
import {Link, withRouter} from "react-router-dom";
import {Collapse, Nav, Navbar, NavbarToggler, NavItem as NavItemOrig} from "reactstrap";

import BP from "./BP";
import {withContext} from "./AppContext";

import {IAppContext} from "../Views/App";

import {Log} from "../Utils/utils";
import {API} from "../Utils/APIShapes";
import {getLang, getLangName, l, Language, setLang} from "../Utils/locales";

import "./NavBar.scss";
import {dispatchAlertFromError} from "./Alerts";


const NavItem = withRouter(({path, location: {pathname}, children, add, onClick, staticContext, ...rest}) =>
  <NavItemOrig {...rest} active={pathToRegexp(path + (add || "")).test(pathname)} onClick={onClick}>
    <Link to={path} className="nav-link" tabIndex="-1">{children}</Link>
  </NavItemOrig>
);

interface Props {
  hidden: boolean,
  context: IAppContext
}
interface State {
  navOpen: boolean,
  menuOpen: boolean,
  initial: boolean
}

class NavBar extends Component<Props, State> {
  state = {navOpen: false, menuOpen: false, initial: true};

  componentDidMount() {
    setTimeout(() => this.setState({initial: false}), 100);
  }
  componentWillUnmount() {
    this.closeNav();
    this.closeMenu();
  }

  navToggle = () => {
    this.setState(s => ({navOpen: !s.navOpen}), () => {
      if (this.state.navOpen)
        document.addEventListener('click', this.closeNav)
    });
  };
  menuToggle = () => {
    this.setState(s => ({menuOpen: !s.menuOpen}), () => {
      if (this.state.menuOpen)
        document.addEventListener('click', this.closeMenu);
    });
  };

  closeMenu = () => {
    this.setState({menuOpen: false});
    document.removeEventListener('click', this.closeMenu);
  };
  closeNav = () => {
    this.setState({navOpen: false});
    document.removeEventListener('click', this.closeNav);
  };

  updateLang = (v) => () => {
    setLang(v);
    this.props.context.forceUpdate();
    const userData = this.props.context.userData;
    if (!userData) throw new Error("userdata undefined?????")
    API.put.user(userData.id, {...userData, language: v}).catch(e => {
      Log.e(e);
      dispatchAlertFromError("Failed to update the user!");
    });
  };

  render() {
    const {userData, userCompany} = this.props.context;
    return <Navbar color="light" light expand="md" className={(!this.props.hidden ? "hidden" : "") + (this.state.initial ? " init" : "")}>
      <Link className="navbar-brand" to="/login/2" tabIndex="-1">{userCompany?.name || "[ezRoute]"}</Link>
      <NavbarToggler onClick={this.navToggle} aria-label={"Navbar toggle"}/>
      <Collapse isOpen={this.state.navOpen} navbar>
        <Nav navbar>
          <NavItem path="/vouchers">{l("titles.vouchers")}</NavItem>
          <NavItem path="/vouchers/new">{l("titles.new-voucher")}</NavItem>
          <NavItem path="/settings">{l("titles.settings")}</NavItem>
          <NavItem path="/settings/locations">{l("titles.locations")}</NavItem>
          <NavItem path="/settings/cars">{l("titles.cars")}</NavItem>
          <NavItem path="/settings/drivers">{l("titles.drivers")}</NavItem>
        </Nav>
        <Nav navbar className="ml-auto">
          <BP below="sm" className="nav-link" elem="a" href="/logout">{l("nav-bar.panel.logout")}</BP>
          {userData ? <li>{userData.username} ({userData.email})</li> : undefined}
        </Nav>
        <img src={`https://www.gravatar.com/avatar/${md5((userData || {}).email || "")}`} alt={"logo"} onClick={this.menuToggle}/>
        <div className={"context-menu" + (this.state.menuOpen ? " open" : "")}>
          <div><i>{l("nav-bar.panel.title")}</i></div>
          <div><a href="/logout">{l("nav-bar.panel.logout")}</a></div>
          <div className="languages">{Object.values(Language).map((v, k) =>
            <div className={getLang() === v ? "curr" : undefined} key={k} onClick={this.updateLang(v)}>{getLangName(v)}</div>
            )}</div>
        </div>
      </Collapse>
    </Navbar>;
  }
}

export default withContext(NavBar);