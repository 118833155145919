import React, {Component} from "react";

import NotFound from "../NotFound";
import {IAppContext} from "../App";

import {withContext} from "../../Components/AppContext";

import {setTitle} from "../../Utils/utils";
import {env} from "../../Utils/Environment";
// import {API} from "../../Utils/APIShapes";


interface Props {
  context: IAppContext
}

class APIInfo extends Component<Props> {
  state = {version: "", sessions: ""};

  componentDidMount() {
    // API.get("/version").then(v => this.setState({version: v})).catch(Log.e);
    // API.get("/printSessions").then(v => this.setState({sessions: v})).catch(Log.e);
    this.props.context.setNavBar(false);
    setTitle("API info");
  }

  render() {
    if (!env.api_path) return <NotFound/>;
    return <div>
      <h2>API info</h2>
      <p>{this.state.version}</p>
      <h4>Sessions</h4>
      {this.state.sessions}
    </div>;
  }
}

export default withContext(APIInfo);