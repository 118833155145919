import React, {Component} from 'react';
import {Link, Redirect} from "react-router-dom";
import {Alert, Button, FormGroup, Input, Label} from "reactstrap";

import {IAppContext} from "../App";

import {withContext} from "../../Components/AppContext";

import {Log, setTitle} from "../../Utils/utils";
import {l, Language, setLang} from "../../Utils/locales";
import {StatusError} from "../../Utils/API";

import "./Login.scss";
import {API} from "../../Utils/APIShapes";


interface State {
  username: string,
  password: string,
  status: number,
  details: undefined|string,
  companies: any,
  loading: boolean
  loaded: boolean
}

class Login extends Component<{context: IAppContext}, State> {
  state: State = {
    username: "",
    password: "",
    status: 0, // -3 other error, -2 server error, -1 login error, 0 fine, 1 success
    details: undefined,
    companies: undefined,
    loading: false,
    loaded: false
  };
  loadListener = () => this.setState({loaded: true});

  componentDidMount() {
    setTitle(l("login.login"));
    this.props.context.setNavBar(false);
    if (!this.props.context.loaded)
      document.addEventListener("loaded", this.loadListener);
    else this.setState({loaded: true});
  }
  componentWillUnmount() {
    document.removeEventListener("loaded", this.loadListener);
  }


  onChange(e) {
    const {name, value} = e.target;
    // @ts-ignore
    this.setState({[name]: value});
  }

  onSubmit(e) {
    e.preventDefault();
    if (!this.state.username || !this.state.password) {
      this.setState({status: 2});
      return;
    }
    if (document.cookie.split(";").filter(v => v.split('=', 2)[0] === "session").length > 0) {
      API.get.logout().finally();
    }
    this.setState({loading: true});
    API.post.login({username: this.state.username, password: this.state.password}).then(ud => {
      this.props.context.setUserData(ud);
      API.get.companies({count: 50}).then(v => {
        this.props.context.setAvailableCompanies(v || []);
        if (ud.language) setLang(ud.language as Language);
        const ld = ud.defaultCompany?.id;
        if (ld) {
          this.props.context.setCompany(ud.defaultCompany);
          this.props.context.redirectTo("/vouchers");
        } else this.props.context.redirectTo("/login/2");
      }).catch(() => this.setState({status: -3, details: "Session Error"}));
    }).catch(this.handleSubmitError);
  }

  handleSubmitError = (err) => {
    Log.e(err);
    if (err instanceof StatusError)
      if (err.code === 401) this.setState({status: -1, loading: false});
      else this.setState({status: -2, details: err.code, loading: false});
    else this.setState({status: -3, details: undefined, loading: false});
  };

  render() {
    if (this.state.loaded && this.props.context.userData) return <Redirect to="/vouchers"/>;

    const stateDetails = this.state.details ? `: ${this.state.details}` : "";
    return <div className="login-cont">
      <div className="spacer"/>
      <header><h3>{l("login.login")}</h3></header>
      <form onSubmit={this.onSubmit.bind(this)}>
        <FormGroup>
          <Label for="username">{l("login.username")}</Label>
          <Input id="username" type="text" name="username" value={this.state.username} onChange={this.onChange.bind(this)} autoFocus/>
        </FormGroup>
        <FormGroup>
          <Label for="password">{l("login.password")}</Label>
          <Input id="password" type="password" name="password" value={this.state.password} onChange={this.onChange.bind(this)}/>
        </FormGroup>
        <Button type="submit" color="primary" disabled={this.state.loading}>{l("login.login")}</Button>
        {this.state.status ===  2 ? <Alert color="warning">{l("login.error.empty")}</Alert> : undefined}
        {this.state.status === -1 ? <Alert color="danger">{l("login.error.invalid")}</Alert> : undefined}
        {this.state.status === -2 ? <Alert color="danger">{l("login.error.server", this.state.details)}</Alert> : undefined}
        {this.state.status === -3 ? <Alert color="danger">{l("login.error.other", stateDetails)}</Alert> : undefined}
      </form>
      <footer>
        <Link to="/reset">{l("login.reset")}</Link> | <a href="https://ezroute.io/">{l("login.sign-up")}</a><br/><br/>
      </footer>
      <div className="spacer"/>
    </div>;
  }
}

export default withContext(Login);