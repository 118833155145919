import React from "react";
import Dragula from "react-dragula";
import polyUtil from "polyline-encoded";
import {MapConsumer, MapContainer, Polyline, ScaleControl, TileLayer, ZoomControl} from "react-leaflet";
import {Button, Col, CustomInput, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";

import {Icon, Icons} from "../../Components/Icon";
import EditName from "../../Components/Inputs/EditName";
import TimePicker from "../../Components/Inputs/TimePicker";
import {DateInput} from "../../Components/Inputs/DateInput";
import {helper} from "../../Components/BP";
import CollapseCard from "../../Components/CollapseCard";
import LocationInput from "../../Components/Inputs/LocationInput";
import {CalendarData, dateFormat} from "../../Components/Inputs/Calendar";
import {env} from "../../Utils/Environment";

import {l, ls} from "../../Utils/locales";
import {animLength} from "../../Utils/static";
import {classMap, formatDistance, formatTime, getPolylineCentreAndSize, Log} from "../../Utils/utils";

import "./Route.scss";
import {Address, API, Location, Point, Route as RouteShape, TempRoutePost, Waypoint} from "../../Utils/APIShapes";
import {LatLng, LeafletMouseEvent} from "leaflet";
import {dispatchAlertFromError} from "../../Components/Alerts";

let negCounter = -1;

const emptyWP = (id?: number): Waypoint => ({id: typeof id !== "undefined" ? id : negCounter--, point: {lat: 0, lng: 0}, polyline: ""});

interface Props {
  data: RouteShape
  onChange(d: RouteShape, cb?: () => any): any
  remove()
  collapsed?: boolean
  calendarData: CalendarData
  invalid?: boolean
}
interface State  {
  trashcan: boolean
  details: boolean
  mapModal: {open: boolean, activeStep: undefined|number}
  refuelModal: boolean
  areNew: {[x: number]: boolean}
  detailsFading: boolean
}

class Route extends React.Component<Props, State> {
  state: State = {
    trashcan: false,
    details: false,
    detailsFading: false,
    mapModal: {open: false, activeStep: undefined},
    refuelModal: false,
    areNew: {}
  };
  refs: {[x: number]: any} = {};
  drake: any;
  trash: any;
  new: boolean = false;
  timeout: NodeJS.Timeout|undefined;
  mapPopup: any;
  lastMapLoc: LatLng | undefined

  constructor(props: Props) {
    super(props);
    const {data, onChange} = props;
    if (!data.waypoints) throw new Error("waypoints are not defined??");
    if (!data.waypoints.length) {
      this.new = true;
      for (let i = 0; i < 2; i++) {
        data.waypoints.push(emptyWP());
      }
      onChange(data);
    }
  }


  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.data !== this.props.data) {
      if (!prevProps.data.waypoints) throw new Error("prev waypoints not defined???");
      if (!this.props.data.waypoints) throw new Error("waypoints are not defined??");
      for (const v of this.props.data.waypoints) {
        if (this.state.areNew[v.id] === undefined) this.setState(s => ({areNew: {...s.areNew, [v.id]: true}}));
      }

    }
  }
  componentWillUnmount(): void {
    if (this.timeout) clearTimeout(this.timeout);
  }


  onTitleChange = (title) => {
    const {onChange, data} = this.props;
    onChange({...data, name: title});
  };
  onCalendarChange = d => {
    const {onChange, data} = this.props;
    onChange({...data, date: d});
  };
  onLocationChange = id => (d: Address|Location) => {
    const {onChange, data} = this.props;
    if (!data.waypoints) throw new Error("waypoints are not defined??");
    let s = data.waypoints.findIndex(v => v.id === id);
    if (s > -1) {
      if ("address" in d) data.waypoints[s].location = d;
      else data.waypoints[s].address = d;
      data.waypoints[s].point = d.point;
      onChange(data);
      this.getRouteDetails();
    }
  };

  getRouteDetails = () => {
    if (!this.props.data.waypoints) throw new Error("waypoints are not defined????");
    let d: Point[] = this.props.data.waypoints.map(v => v.point);
    if (d.some(v => !v || (!v.lat && !v.lng))) {
      console.log("skipping,", d);
      return;
    }
    if (d.length < 2) {
      console.log("skipping, < 2");
      return;
    }
    d = d.map(v => ({lat: v.lat, lng: v.lng}));
    API.post.calcRoute(d).then(async (v) => {
      const data = this.props.data as TempRoutePost;
      data.duration = v.duration;
      data.distance = v.distance;
      data.polyline = v.polyline;
      data.id = v.id;
      if (!data.waypoints) throw new Error("data.waypoints undef");
      v.waypoints.shift();
      if (v.waypoints.length !== data.waypoints.length) throw new Error("lengths mismatch");
      for (let i = 0; i < v.waypoints.length; i++){
        let w = v.waypoints[i];
        const {id, ...rest} = data.waypoints[i] as any;
        data.waypoints[i] = rest;
        if (!w.polyline) continue;
        data.waypoints[i].polyline = w.polyline;
        data.waypoints[i].distanceToNext = w.distanceToNext;
        data.waypoints[i].durationToNext = w.durationToNext;
      }
      console.log(data);
      this.props.onChange(data as RouteShape);
    }).catch(e => {
      Log.e(e);
      dispatchAlertFromError("Failed to calculate route!");
    });
  };

  addStep = e => {
    e.preventDefault();
    const {data, onChange} = this.props;
    if (!data.waypoints) throw new Error("waypoints are not defined??");
    data.waypoints.push(emptyWP());
    onChange(data);
  };
  openRefuelingModal = e => {
    e.preventDefault();
    this.setState({refuelModal: true});
  };
  addRefuelingStep = d => {
    const {data, onChange} = this.props;
    if (!data.waypoints) throw new Error("waypoints are not defined??");
    d.id = negCounter--;
    data.waypoints.push(d);
    onChange(data);
    this.getRouteDetails();
  };

  deleteSelf = e => {
    e.preventDefault();
    if (window.confirm(ls("vouchers.new.routes.remove-alert"))) this.props.remove();
  };
  toggleDetails = ({target: {checked}}) => {
    if (checked) {
      this.getRouteDetails();
      this.setState({details: true});
    } else {
      this.setState({detailsFading: true}, () => {
        this.timeout = global.setTimeout(() => this.setState({details: false}, () => {
          this.timeout = setTimeout(() => this.setState({detailsFading: false}), animLength*2);
        }), 0);
      });
    }
  };

  openModal = e => {
    e.preventDefault();
    this.setState(s => ({mapModal: {...s.mapModal, open: true}}));
  };

  calcMapZoom = ([width, height], [lat,,]) => {
    let {innerWidth, innerHeight} = window;
    if (helper.above("sm")) innerWidth *= .9;
    const mpp1 = 40075016.686 * Math.cos(lat*Math.PI/180);
    const mSize = [
      width * (111132.954 - 559.822 * Math.cos( 2 * lat * Math.PI / 180) + 1.175 * Math.cos( 4 * lat * Math.PI / 180)),
      height * 111132.954 * Math.cos(lat * Math.PI / 180)
    ];
    let i = 19;
    while (true) {
      const mpp2 = mpp1 / (2**(i+8));
      const pSize = mSize.map(v => v/mpp2);
      if ((pSize[0] <= innerWidth && pSize[1] <= innerHeight) || i === 0) return i-1;
      i--;
    }
  };

  mapPopupListener = e => {
    if (this.mapPopup.contains(e.target)) return;
    this.mapPopup.style.visibility = "hidden";
    document.body.removeEventListener("mousedown", this.mapPopupListener);
  }
  showMapPopup = (e: LeafletMouseEvent) => {
    if (!this.mapPopup) return;
    const {containerPoint: {x, y}, originalEvent} = e;
    this.lastMapLoc = e.latlng;
    const {width: pW, height: pH} = (originalEvent.target as HTMLElement).getBoundingClientRect();
    const {width, height} = this.mapPopup.getBoundingClientRect();
    this.mapPopup.style.visibility = "visible";
    this.mapPopup.style.top  = `${y < pH - height ? y : y - height}px`;
    this.mapPopup.style.left = `${x < pW - width  ? x : x - width }px`;
    document.body.addEventListener("mousedown", this.mapPopupListener);
  }
  addStepFromMap = (prepend) => (e) => {
    if (typeof this.lastMapLoc === "undefined") return;
    const {data, onChange} = this.props;
    if (!data.waypoints) throw new Error("waypoints are not defined??");
    console.log(prepend, e, this.lastMapLoc)

    const wp: Waypoint = {
      ...emptyWP(),
      point: (this.lastMapLoc as Point),
    }
    if (prepend) data.waypoints.unshift(wp)
    else data.waypoints.push(wp);
    onChange(data);
    this.getRouteDetails();

    this.mapPopup.style.visibility = "hidden";
    document.body.removeEventListener("mousedown", this.mapPopupListener);
  }


  drakeDrop = r => (el, tg) => {
    const ord: number[] = [];
    r.childNodes.forEach(v => {
      (v.childNodes[1] as Element).classList.remove("v-circle-prev");
      const [first] = Object.entries(this.refs).find(([, n]) => n === v) || [];
      if (typeof first !== "undefined") ord.push(parseInt(first));
    });
    const newSteps: Waypoint[] = [];
    for (const i of ord) {
      newSteps.push(this.props.data.waypoints?.find(v => v.id === i)!);
    }
    if (tg === this.trash) {
      r.appendChild(el);
    }
    this.setState({trashcan: false});
    this.props.onChange({...this.props.data, waypoints: newSteps}, this.getRouteDetails);
  };

  setupDrake = (r) => {
    if (!r || this.drake || !this.trash) return;
    this.drake = Dragula([r, this.trash], {
      moves: (el, source, handle) => handle.classList.contains("v-dragger")
    });
    this.drake.on("drag", (el) => {
      this.setState(s => {
        const areNew = {};
        Object.keys(s.areNew).forEach(k => areNew[k] = false);
        return {trashcan: true, areNew: areNew, details: false};
      });
      if (el.previousSibling) el.previousSibling.childNodes[1].classList.add("v-circle-prev");
    });
    this.drake.on("drop", this.drakeDrop(r));
    this.drake.on("cancel", () => {
      r.childNodes.forEach(v => v.childNodes[1].classList.remove("v-circle-prev"));
      this.setState({trashcan: false});
    });
    this.drake.on("shadow", (el, tg) => {
      el.style.display = tg === this.trash ? "none" : "flex";
      r.childNodes.forEach(v => v.childNodes[1].classList.remove("v-circle-prev"));
      if (el.previousSibling && el.previousSibling.childNodes[1]) el.previousSibling.childNodes[1].classList.add("v-circle-prev");
    });
  };


  waypointsToDOM = (waypoints) => {
    const {details, detailsFading} = this.state;
    return waypoints?.map((v, k) => [
      <div key={v.id} className={classMap("row route-address", this.state.areNew[v.id] && "fade-anim")}
           ref={r => this.refs = {...this.refs, [v.id]: r}}>
        <Col xs="auto" className="v-dragger"><span className="hover-text">{l("vouchers.new.routes.drag-info")}</span></Col>
        <Col xs="auto" className={classMap("v-circle", v.refueling && "v-circle-fuel")}/>
        <Col xs="auto">{ls("vouchers.new.routes.fields.step", -v.id)}</Col>
        <Col><LocationInput onChange={this.onLocationChange(v.id)}
                            autoValue={v}/>{/*value={v.address?.fullAddress || (v.address?.point && v.address?.point.id && `${v.address?.point.lat.toFixed(4)}; ${v.address?.point.lng.toFixed(4)}`) || ""}/>*/}
        </Col>
      </div>,
      (details || detailsFading) && k !== waypoints.length - 1 ?
        <div className={classMap("details", (details && !detailsFading) && "details-in", (detailsFading && !details) && "details-out")} key={`d${k}`}>
          <span>{l("vouchers.new.routes.details.time", formatTime(v.durationToNext))}</span>
          <span>{l("vouchers.new.routes.details.distance", formatDistance(v.distanceToNext))}</span>
        </div> : undefined
    ])
  };

  route = (append, notMap: boolean, invalid) => {
    const {collapsed, data: {date, waypoints, name, distance: fDist, duration: fTime}, calendarData} = this.props;
    const {details, detailsFading} = this.state;
    console.log(waypoints);

    return <CollapseCard className={classMap("route-cont", !collapsed && this.new && "fade-anim", invalid && "route-invalid")}
                         title={<EditName value={name || ""} onChange={this.onTitleChange}/>} collapsed={collapsed} key={name}>
      <span className={classMap("route-remove", !this.state.trashcan && "hidden")} ref={r => this.trash = r}><Icon name={Icons.Remove}/></span>
      {notMap ?
        <Row><Col>{l("vouchers.new.routes.fields.date")}</Col><Col><DateInput value={new Date(date || 0)} data={calendarData} onChange={this.onCalendarChange}
                                                                              key={name}/></Col></Row> : undefined}
      <div className={classMap("step-cont", (details || detailsFading) && "detailed")} ref={this.setupDrake}>
        {this.waypointsToDOM(waypoints)}
      </div>
      <Row className="route-add">
        <Col><a href={"/"} className="new-v-link" onClick={this.addStep}>{l("vouchers.new.routes.fields.add-addr")}</a></Col>
        <Col><a href={"/"} className="new-v-link" onClick={this.openRefuelingModal}>{l("vouchers.new.routes.fields.add-fuel")}</a></Col>
      </Row>
      <Row>
        {notMap ? <Col><a href="/" className="modal-map-link" onClick={this.openModal}><Icon name={Icons.Map}/>{l("vouchers.new.routes.fields.map")}
        </a></Col> : undefined}
        <Col><CustomInput type="switch" id={`details-${name}`} label={l("vouchers.new.routes.fields.details")} checked={details} onChange={this.toggleDetails}/></Col>
      </Row>
      <Row><Col>{l("vouchers.new.routes.fields.time")}</Col><Col><Input disabled value={formatTime(fTime)}/></Col></Row>
      <Row><Col>{l("vouchers.new.routes.fields.distance")}</Col><Col><Input disabled value={formatDistance(fDist)}/></Col></Row>
      {append}
    </CollapseCard>;
  };

  render() {
    const {data: {date, polyline = ""}, calendarData} = this.props;
    const {mapModal, refuelModal} = this.state;
    const invalid = calendarData[dateFormat(new Date(date || 0))] === -1 || this.props.invalid;
    const lines = polyUtil.decode(polyline);
    let {center, size} = getPolylineCentreAndSize(lines);
    if (size.every(v => v < 0)) {
      center = [56.88, 24.6];
      size = [2, 2];
    }
    const modalToggle = () => this.setState(s => ({mapModal: {...s.mapModal, open: !s.mapModal.open}}));
    const removeLink = <div className="align-right"><a href="/" className="remove-link" onClick={this.deleteSelf}>{l("vouchers.new.routes.remove")}</a></div>;
    const saveBtn = <Row className="save-row"><Button color="success" onClick={this.getRouteDetails}>Save</Button></Row>;
    return <>
      {this.route(removeLink, true, invalid)}
      <Modal isOpen={mapModal.open} autoFocus={false} toggle={modalToggle} className="map-modal">
        <div className="close-btn" onClick={modalToggle}><Icon name={Icons.Close}/></div>
        <div className="map-drawer">{this.route(saveBtn, false, invalid)}</div>
        <MapContainer center={center} zoom={this.calcMapZoom(size, center)} minZoom={6} maxZoom={19} zoomControl={false}>
          <MapConsumer>{map => {
            map.on("contextmenu", this.showMapPopup);
            return null;
          }}</MapConsumer>
          <TileLayer attribution={ls("misc.map-attribution")} url={env.map_url}/>
          <ScaleControl imperial={false} position="bottomright"/>
          <ZoomControl position="bottomright"/>
          <Polyline positions={lines}/>
        </MapContainer>
        <div className="map-popup" style={{visibility: "hidden"}} ref={r => this.mapPopup = r}>
          <div onClick={this.addStepFromMap(true )}>Add start address</div>
          <div onClick={this.addStepFromMap(false)}>Add new address</div>
        </div>
      </Modal>
      <RefuelModal isOpen={refuelModal} toggle={() => this.setState({refuelModal: !refuelModal})} onSave={this.addRefuelingStep}/>
    </>;
  }
}

interface RefuelModalProps {
  isOpen: boolean
  toggle(): any
  onSave(d: Waypoint): any
}
interface RefuelModalState {
  waypoint: Partial<Waypoint>
  timeInput: number
  fuelInput: number
  addressInput: number
}

class RefuelModal extends React.Component<RefuelModalProps, RefuelModalState> {
  state: RefuelModalState = {
    waypoint: {
      refueling: true
    },
    timeInput: 0, fuelInput: 0, addressInput: 0
  };

  clear = () => this.setState({
    waypoint: {
      refueling: true
    }
  });

  cancel = () => {
    this.clear();
    this.props.toggle();
  };
  save = e => {
    e.preventDefault();
    e.stopPropagation();
    const {waypoint} = this.state;
    let valid = true;
    if (typeof waypoint.fuel === "undefined") {
      this.setState({fuelInput: 1});
      valid = false;
    } else if (waypoint.fuel <= 0) {
      this.setState({fuelInput: 2});
      valid = false;
    }
    if (!waypoint.time)     {
      this.setState({timeInput: 1});
      valid = false;
    }
    if (!waypoint.address)  {
      this.setState({addressInput: 1});
      valid = false;
    }
    if (valid) {
      this.props.onSave(waypoint as Waypoint);
      this.clear();
      this.props.toggle();
    }
  };

  onLocationChange = (v) => {
    if (this.state.addressInput !== 0) {
      if (v) this.setState({addressInput: -1});
      else this.setState({addressInput: 1});
    }
    this.setState(s => ({waypoint: {...s.waypoint, address: v}}));
  };
  onTimeChange = (v) => {
    if (this.state.timeInput !== 0) {
      if (v) this.setState({timeInput: -1});
      else this.setState({timeInput: 1});
    }
    this.setState(s => ({waypoint: {...s.waypoint, time: v}}));
  };
  onAmountChange = ({target: {value: d}}) => {
    const f = parseFloat(d || "0");
    if (this.state.fuelInput !== 0) {
      if (f > 0) this.setState({fuelInput: -1});
      else if (d) this.setState({fuelInput: 2});
      else this.setState({fuelInput: 1});
    }
    this.setState(s => ({waypoint: {...s.waypoint, fuel: d ? f : undefined}}));
  };

  render() {
    const {isOpen, toggle} = this.props;
    const {waypoint: v, ...s} = this.state;
    return <Modal isOpen={isOpen} autoFocus={false} toggle={toggle} size="lg">
      <form>
        <ModalHeader>{l("vouchers.modals.refueling-point.title")}</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label>{l("vouchers.new.routes.refuel.location")}</Label>
                <LocationInput value={v.address?.fullAddress || (v.address?.point && v.address?.point.id && `${v.address?.point.lat.toFixed(4)}; ${v.address?.point.lng.toFixed(4)}`) || ""}
                               onChange={this.onLocationChange} required autoFocus className={{[-1]: "feedback__field--good", 1: "feedback__field--bad"}[Math.sign(s.addressInput)]}/>
                {s.addressInput === -1 ? <div className="feedback--good">{l("global.forms.valid")}</div> : null}
                {s.addressInput ===  1 ? <div className="feedback--bad">{l("global.forms.fill-field")}</div> : null}
              </FormGroup>
              <FormGroup>
                <Label>{l("vouchers.new.routes.refuel.amount")}</Label>
                <Input type="number" value={typeof v.fuel === "number" ? v.fuel : ""} onChange={this.onAmountChange} required className={{[-1]: "feedback__field--good", 1: "feedback__field--bad"}[Math.sign(s.fuelInput)]}/>
                {s.fuelInput === -1 ? <div className="feedback--good">{l("global.forms.valid")}</div> : null}
                {s.fuelInput ===  1 ? <div className="feedback--bad">{l("global.forms.fill-field")}</div> : null}
                {s.fuelInput ===  2 ? <div className="feedback--bad">{l("global.forms.invalid-value")}</div> : null}
              </FormGroup>
            </Col>
            <Col xs="auto">
              <div>
                <TimePicker onChange={this.onTimeChange} className={{[-1]: "feedback__field--good", 1: "feedback__field--bad"}[Math.sign(s.timeInput)]}/>
                {s.timeInput === -1 ? <div className="feedback--good">{l("global.forms.valid")}</div> : null}
                {s.timeInput ===  1 ? <div className="feedback--bad">{l("global.forms.fill-field")}</div> : null}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row className="btns">
            <Button color="success" type="submit" onClick={this.save}>{l("global.save")}</Button>
            <Button onClick={this.cancel}>{l("global.cancel")}</Button>
          </Row>
        </ModalFooter>
      </form>
    </Modal>;
  }
}

export default Route;
