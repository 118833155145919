import React from 'react';
import SmoothCollapse from "react-smooth-collapse";

import {classMap} from "../../Utils/utils";

import './DropdownInput.scss';


export interface DropdownProps {
  className?: string,
  autoClose?: boolean,
  beforeInput?: JSX.Element, afterInput?: JSX.Element,
  value: any, onChange(v),
  keyDown?(k),
  children?: JSX.Element,
  onOpen?(), onClose?(),
  onFocus?(), onBlur?(),
  placeholder?: string,
  id?: string
}
interface PureProps extends DropdownProps {
  forwardedRef: any
}
interface State {
  open: boolean,
  upwards: boolean
}

export class DropdownInputPure extends React.Component<PureProps, State> {
  state = {
    open: false,
    upwards: false
  };
  ref: HTMLElement|null = null;

  componentWillUnmount() {
    document.removeEventListener("click", this.bodyClick);
  }

  checkDirection = () => {
    let upwards = this.state.upwards;
    if (this.ref && !this.state.open) {
      const size = this.ref.getBoundingClientRect();
      upwards = size.bottom > 300 && (window.innerHeight - size.top) < 300;
    }
    this.setState({upwards: upwards});
  };

  open = () => {
    this.checkDirection();
    const {onOpen = () => {/* do nothing */}, onClose = () => {/* do nothing */}} = this.props;
    if (!this.state.open) onOpen();
    else onClose();
    this.setState(s => ({open: !s.open}), () => {
      document.addEventListener("click", this.bodyClick, {capture: true});
    });
  };
  close = () => {
    const {onClose = () => {/* do nothing */}} = this.props;
    onClose();
    document.removeEventListener("click", this.bodyClick, {capture: true});
    this.setState({open: false});
  };

  bodyClick = ({target}) => {
    if (!this.ref) return;
    if (this.ref.contains(target) && (!this.props.autoClose || target.tagName !== "OPTION")) return;
    this.close();
  };

  keyDown = e => {
    const {key} = e;

    const {keyDown = () => {/* do nothing */}} = this.props;
    keyDown(e);

    if (this.state.open) {
      if (["Enter", "Tab"].includes(key)) {
        e.preventDefault();
        this.close();
      }
    } else if (key === "Enter") this.open();

    if (key === "Escape") this.close();
  };

  render() {
    const {placeholder, beforeInput, afterInput, children, className, value, onChange, forwardedRef, onFocus, onBlur, id} = this.props;
    const {upwards, open} = this.state;
    return <div className={classMap("dropdown-input", className, open && "open", upwards && "upwards")}
                ref={r => this.ref = r} onFocus={onFocus} onBlur={onBlur}>
      {beforeInput}
      <input className="head" type="text" ref={forwardedRef} value={value} onChange={onChange} placeholder={placeholder}
             onKeyDown={this.keyDown} onClick={this.open} id={id}/>
      {afterInput}
      <div className="body-wrap">
        <SmoothCollapse expanded={open} eagerRender>
          {children}
        </SmoothCollapse>
      </div>
    </div>;
  }
}

export default React.forwardRef((props: DropdownProps, ref) => <DropdownInputPure {...props} forwardedRef={ref}/>)