import React, {Component} from 'react';
import {Button} from "reactstrap";
import {withCookies} from "react-cookie";

import {l} from "../Utils/locales";
import {cookieAllowStorage} from "../Utils/static";

import "./CookieBanner.scss";


class CookieBanner extends Component<{cookies?: any}> {
  state = {accepted: true, accepting: false};
  cookies: any;

  componentDidMount() {
    this.cookies = this.props.cookies;
    if (localStorage.getItem(cookieAllowStorage) !== "true") this.setState({accepted: false});
  }

  acceptCookies = () => {
    localStorage.setItem(cookieAllowStorage, "true");
    this.setState({accepting: true}, () => setTimeout(() => this.setState({accepted: true}), 1000));
  };

  render() {
    const animDirection = this.state.accepting ? "slide-anim-out" : "slide-anim-in";
    return this.state.accepted ? null : <div className={`cookie-banner ${animDirection}`}><div>
      <span>{l("cookie-banner.text")} <a href="/cookies.html">{l("cookie-banner.link")}</a></span>
      <div className="cb-btn"><Button onClick={this.acceptCookies}>{l("cookie-banner.btn")}</Button></div>
    </div></div>;
  }
}

export default withCookies(CookieBanner);