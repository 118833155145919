import React, {Component} from "react";
import {CustomInput, Input} from "reactstrap";

import {IAppContext} from "../App";

import {Icon, Icons} from "../../Components/Icon";
import TimePicker from "../../Components/Inputs/TimePicker";
import {DateInput} from "../../Components/Inputs/DateInput";
import CustomSelect from "../../Components/Inputs/CustomSelect";
import {withContext} from "../../Components/AppContext";
import LocationInput from "../../Components/Inputs/LocationInput";

import {setTitle} from "../../Utils/utils";

import NotFound from "../NotFound";
import {env} from "../../Utils/Environment";


interface Props {
  context: IAppContext
}
interface State {
  values: any,
  date: Date
}

interface Payloads {
  short: JSX.Element[],
  long: JSX.Element[],
  wide: JSX.Element[],
  longWide: JSX.Element[],
  search: JSX.Element[]
}

class TestSite extends Component<Props, State> {
  state = {
    values: {2: "c-2"},
    date: new Date()
  };

  componentDidMount() {
    this.props.context.setNavBar(false);
    setTitle("Test Site");
  }

  change = k => v => {
    console.log(k, v);
    this.setState(s => ({values: {...s.values, [k]: v}}));
  };

  render() {
    if (!env.api_path) return <NotFound/>;
    const payloads: Payloads = {short: [], long: [], wide: [], longWide: [], search: []};
    for (let i = 0; i < 5; i++) {
      payloads.short.push(<option key={i} value={`c-${i}`}>V {i}</option>);
      payloads.wide.push(<option key={i}>V {i} {"- ".repeat(300)} END</option>)
    }
    for (let i = 0; i < 50; i++) {
      payloads.long.push(<option key={i}>V {i}</option>);
      payloads.longWide.push(<option key={i}>V {i} {"- ".repeat(300)} END</option>)
    }
    const text = "Proin cursus, mi sed ultrices vehicula, nunc nibh pretium magna, sed molestie nisl magna vel massa. Vestibulum nec dapibus tortor. Duis in mattis dolor, ut lobortis arcu. Sed et sapien leo. Maecenas ornare, nisi a feugiat pulvinar, enim massa commodo ligula, non imperdiet magna tortor quis urna. Praesent mattis erat a diam tempus, at lobortis eros lacinia. Aenean in lacinia magna. Donec semper metus rhoncus, pulvinar neque vel, viverra eros. Maecenas lorem turpis, aliquet eget quam et, pretium placerat odio. Pellentesque in commodo magna. Fusce ullamcorper lobortis turpis sed elementum. Suspendisse euismod vel sem quis laoreet. Sed molestie laoreet nunc, nec bibendum nisl. Nullam ante odio, rhoncus ut dui ut, pretium euismod ex. Nunc in lectus ut dolor facilisis volutpat ac in lorem. Donec egestas dui a nisl tempus commodo.";
    for (const i of (text.match(/(.*?\s){3}/g) || [])) payloads.search.push(<option key={i}>{i}</option>);
    const v = this.state.values;
    return <div className="test">
      <style>{`.test {padding-top: 60%} .test > div {margin: 4px} .icons .ico {height: 24px; width: 24px}`}</style>
      {/*<Loading/>*/}
      <TimePicker onChange={this.change(30)}/>
      <div className="icons"><Icon name={Icons.Danger}/><Icon name={Icons.Wait}/><Icon name={Icons.Fuel}/></div>
      <div><LocationInput onChange={this.change(20)} value={v[20]}/></div>
      <div>loc: {v[20]}</div>
      <div><DateInput onChange={this.change(10)} value={v[10] || new Date()}/></div>
      <div><DateInput onChange={this.change(11)} value={v[11] || new Date()}/></div>
      <div><DateInput onChange={this.change(12)} value={v[12] || new Date()}/></div>
      <div><CustomSelect onChange={this.change(0)} value={v[0]} placeholder="Select no value" emptyValue="No values"/></div>
      <div><CustomSelect onChange={this.change(1)} value={v[1]} placeholder="Select 1 value"><option>V 1</option></CustomSelect></div>
      <div><CustomSelect onChange={this.change(2)} value={v[2]} placeholder="Select value">{payloads.short}</CustomSelect></div>
      <div><CustomSelect onChange={this.change(3)} value={v[3]} loading={true}>{payloads.long}</CustomSelect></div>
      <div><CustomSelect onChange={this.change(4)} value={v[4]} placeholder="Search value" searchable>{payloads.search}</CustomSelect></div>
      <div><CustomInput id="_1" type="select" onChange={e => console.log({...e})}>{payloads.short}</CustomInput></div>
      <div><Input/></div>
      <div><Input type="date"/></div>
      <div><DateInput onChange={this.change(13)} value={v[13] || new Date()}/></div>
    </div>;
  }
}

export default withContext(TestSite);