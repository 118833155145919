import React, {Component} from "react";

import {l} from "../Utils/locales";
import {setTitle} from "../Utils/utils";


class NotFound extends Component {

  componentDidMount() {
    setTitle(l("e404.title"));
  }

  render() {
    return <div><h1>{l("e404.head")}</h1><h3>{l("e404.text")}</h3></div>;
  }
}

export default NotFound;