import {fetchParse, fetchParsePaginated, PaginationData} from "./API";
import {Merge} from "./utils";

interface IDAble {
  id?: number
}
export interface ID extends IDAble {
  id: number
}

export interface Permission {
  permissionLevel?: "COM"|"VOU"|"ADM"
  create?: boolean
  read?: boolean
  update?: boolean
  delete?: boolean
}

export interface Point extends IDAble {
  lat: number
  lng: number
}
export interface Country extends IDAble {
  iso?: string
  name: string
  iso3?: string
  numcode?: number
  phonecode?: number
}
export interface AddressWithoutID {
  point: Point
  city?: string
  street?: string
  region?: string
  building: string
  countryID?: number
  country: Country
  fullAddress: string
  zipCode?: string
  subRegion?: string
  w3wAddress: string
  kIJShash?: string
  corpuss?: string
}
export interface Address extends AddressWithoutID, ID {}
export interface LocationWithoutID {
  shortName: string
  address: Address
  point: Point
  w3wAddress: string
  favorite: boolean
}
export interface Location extends LocationWithoutID, ID {}
export interface CompanyWithoutID {
  location: Location
  regNr: string
  name: string
  active: boolean
}
export interface Company extends CompanyWithoutID, ID {}

export interface PlaceSuggestion extends ID {
  resultType: "Address"|"Location"|"searchResult"
  country: Country
  point?: Point
  city?: string
  street?: string
  building: string
  region?: string
  fullAddress: string
  subRegion?: string
  w3wAddress?: string
  shortName?: string
  corpuss?: string
  zipCode?: string
  kIJShash?: string
}

export interface NewUser {
  username: string
  pwd: string
  email: string
  superuser: boolean
  permission: Permission
  language?: string
  defaultCompanyID?: number
  countryID?: number
}
export interface UserWithoutID {
  username: string
  email: string
  superuser: boolean
  active: boolean
  language?: string
  defaultCompany?: Company
  country?: Country
}
export interface User extends UserWithoutID, ID {}
export interface LoginInfo {
  username: string
  password: string
}
export interface PasswordChange {
  oldPassword: string
  newPassword: string
  repeatNew: string
}
export interface ResetPassword {
  password: string
  repeat: string
}
export interface Email {
  email: string
}

export interface EmailMessage {
  subject: string
  message: string
}
export interface EmailSetup {
  userID: number
  SMTPserver: string
  port: string
  password: string
  email: string
}

export interface CarWithoutID {
  nrPlate: string
  carType?: string
  model: string
  fuel: number
  fuelCons: number
  fuelType?: string
  odometer: number
  preferedDriver?: Driver
}
export interface Car extends CarWithoutID, ID {}

export interface DriverWithoutID {
  name: string
  surname: string
}
export interface Driver extends DriverWithoutID, ID {}

export type Polyline = string;

export interface WaypointWithoutID {
  durationToNext?: number
  distanceToNext?: number
  refueling?: boolean
  fuel?: number
  time?: string
  point: Point
  location?: Location
  address?: Address
  polyline: Polyline
}
export interface Waypoint extends WaypointWithoutID, ID {}
export interface RouteWithoutID {
  name?: string
  date?: number
  distance: number
  duration: number
  waypoints?: Waypoint[]
  polyline?: Polyline
}
export interface Route extends RouteWithoutID, ID {}
export type TempRoute = Merge<Route, {
  waypoints: WaypointWithoutID[],
  polyline: Polyline
}>;
export type TempRoutePost = Merge<TempRoute, {
  waypoints: Merge<WaypointWithoutID, {
    address?: ID,
    location?: ID,
  }>[],
}>

export interface VoucherWithoutID {
  driver: Driver
  car: Car
  userID: number
  company: Company
  date: number
  voucherNr: string
  name: string
  startingFuel: number
  factCons: number
  finished: boolean
  routes?: Route[]
  fuelIssued: number
  fuelReturning: number
}
export interface Voucher extends VoucherWithoutID, ID {}

export interface EventWithoutID {
  eventType: string
  date: number
  eid?: number
  value?: number
  message?: string
  profileID?: number
}
export interface Event extends EventWithoutID, ID {}

//<editor-fold desc="API definition">
interface APIPost {
  sendEmail(id: number, msg: EmailMessage) // /user/{id}/sendEmail

  reverseGeocode(point: Point): Promise<Address> // /reverse

  login(loginInfo: LoginInfo): Promise<User> // /login

  calcRoute(addresses: Point[]): Promise<TempRoute> // /route
  route(route: Route): Promise<Route>

  location (location: LocationWithoutID): Promise<ID>
  address  (address:  AddressWithoutID):  Promise<ID>
  car      (car:      CarWithoutID):      Promise<ID>
  voucher  (voucher:  VoucherWithoutID):  Promise<ID>
  driver   (driver:   DriverWithoutID):   Promise<ID>
  user     (user:     NewUser):           Promise<ID>
  company  (company:  CompanyWithoutID):  Promise<ID>
  event    (event:    EventWithoutID):    Promise<ID>
}
interface APIGet {
  logout() // /logout
  resetPassword(secret: string) // /resetPassword/{secret}
  currentUser(): Promise<User> // /currentUser

  autocompletePlace(placePart: string): Promise<PlaceSuggestion[]> // /autocompletePlace/{placePart}
  autocompleteResults(id: number): Promise<Address> // /autocompletePlace/results/{id}

  countries (pd: PaginationData): Promise<Country[]>
  locations (pd: PaginationData): Promise<Location[]>
  addresses (pd: PaginationData): Promise<Address[]>
  cars      (pd: PaginationData): Promise<Car[]>
  vouchers  (pd: PaginationData): Promise<Voucher[]>
  drivers   (pd: PaginationData): Promise<Driver[]>
  users     (pd: PaginationData): Promise<User[]>
  companies (pd: PaginationData): Promise<Company[]>
  events    (pd: PaginationData): Promise<Event[]>

  country  (id: number): Promise<Country>
  location (id: number): Promise<Location>
  address  (id: number): Promise<Address>
  car      (id: number): Promise<Car>
  voucher  (id: number): Promise<Voucher>
  driver   (id: number): Promise<Driver>
  user     (id: number): Promise<User>
  company  (id: number): Promise<Company>
  event    (id: number): Promise<Event>
}
interface APIPut {
  setupEmail(setup: EmailSetup) // /setupEmail

  changePassword(passwordChange: PasswordChange) // /user/changePassword
  resetPassword(secret: string, resetPassword: ResetPassword) // /resetPassword/{secret}
  resetPasswordEmail(email: Email) // /resetPassword
  userActivate(id: number, user: User): Promise<User> // /user/{id}/activate
  userDeactivate(id: number, user: User): Promise<User> // /user/{id}/deactivate

  route(id: number, addresses: Address[]): Promise<Route> // /route/{id}

  companyActivate(id: number, company: Company): Promise<Company> // /company/{id}/activate
  companyDeactivate(id: number, company: Company): Promise<Company> // /company/{id}/deactivate

  location (id: number, location: Partial<Location>): Promise<Location>
  address  (id: number, address: Partial<Address>):   Promise<Address>
  car      (id: number, car: Partial<Car>):           Promise<Car>
  voucher  (id: number, voucher: Partial<Voucher>):   Promise<Voucher>
  driver   (id: number, driver: Partial<Driver>):     Promise<Driver>
  user     (id: number, user: Partial<User>):         Promise<User>
  company  (id: number, company: Partial<Company>):   Promise<Company>
  event    (id: number, event: Partial<Event>):       Promise<Event>
}
interface APIDelete {
  profile  (id: number): Promise<ID>
  location (id: number): Promise<ID>
  address  (id: number): Promise<ID>
  car      (id: number): Promise<ID>
  voucher  (id: number): Promise<ID>
  driver   (id: number): Promise<ID>
  user     (id: number): Promise<ID>
  company  (id: number): Promise<ID>
  event    (id: number): Promise<ID>
}

interface IAPI {
  post: APIPost
  get: APIGet
  put: APIPut
  del: APIDelete
}
//</editor-fold>

export const API: IAPI = {
  post: {
    sendEmail: async (id: number, msg: EmailMessage) => fetchParse("post", `/user/${id}/sendEmail`, msg),

    reverseGeocode: async (point: Point): Promise<Address> => await fetchParse("post", "/reverse", point) as Promise<Address>,

    login: async (loginInfo: LoginInfo): Promise<User> => await fetchParse("post", "/login", loginInfo) as Promise<User>,

    calcRoute: async (addresses: Point[]): Promise<TempRoute> => await fetchParse("post", "/calcRoute", addresses) as Promise<TempRoute>,
    route: async (route: Route): Promise<Route> => await fetchParse("post", "/route", route) as Promise<Route>,

    location: async (location: LocationWithoutID): Promise<ID> => await fetchParse("post", "/location", location) as Promise<ID>,
    address:  async (address:  AddressWithoutID):  Promise<ID> => await fetchParse("post", "/address",  address)  as Promise<ID>,
    car:      async (car:      CarWithoutID):      Promise<ID> => await fetchParse("post", "/car",      car)      as Promise<ID>,
    voucher:  async (voucher:  VoucherWithoutID):  Promise<ID> => await fetchParse("post", "/voucher",  voucher)  as Promise<ID>,
    driver:   async (driver:   DriverWithoutID):   Promise<ID> => await fetchParse("post", "/driver",   driver)   as Promise<ID>,
    user:     async (user:     NewUser):           Promise<ID> => await fetchParse("post", "/user",     user)     as Promise<ID>,
    company:  async (company:  CompanyWithoutID):  Promise<ID> => await fetchParse("post", "/company",  company)  as Promise<ID>,
    event:    async (event:    EventWithoutID):    Promise<ID> => await fetchParse("post", "/event",    event)    as Promise<ID>,
  },
  get: {
    logout: async () => fetchParse("get", "/logout"),
    resetPassword: async (secret: string) => fetchParse("get", `/resetPassword/${secret}`),
    currentUser: async (): Promise<User> => await fetchParse("get", "/currentUser") as Promise<User>,

    autocompletePlace: async (placePart: string): Promise<PlaceSuggestion[]> => await fetchParse("get", `/autocompletePlace/${placePart}`) as Promise<PlaceSuggestion[]>,
    autocompleteResults: async (id: number): Promise<Address> => await fetchParse("get", `/autocompletePlace/results/${id}`) as Promise<Address>,

    countries: async (pd: PaginationData): Promise<Country[]>  => await fetchParsePaginated("get", "/country", pd)  as Promise<Country[]>,
    locations: async (pd: PaginationData): Promise<Location[]> => await fetchParsePaginated("get", "/location", pd) as Promise<Location[]>,
    addresses: async (pd: PaginationData): Promise<Address[]>  => await fetchParsePaginated("get", "/address", pd)  as Promise<Address[]>,
    cars:      async (pd: PaginationData): Promise<Car[]>      => await fetchParsePaginated("get", "/car", pd)      as Promise<Car[]>,
    vouchers:  async (pd: PaginationData): Promise<Voucher[]>  => await fetchParsePaginated("get", "/voucher", pd)  as Promise<Voucher[]>,
    drivers:   async (pd: PaginationData): Promise<Driver[]>   => await fetchParsePaginated("get", "/driver", pd)   as Promise<Driver[]>,
    users:     async (pd: PaginationData): Promise<User[]>     => await fetchParsePaginated("get", "/user", pd)     as Promise<User[]>,
    companies: async (pd: PaginationData): Promise<Company[]>  => await fetchParsePaginated("get", "/company", pd)  as Promise<Company[]>,
    events:    async (pd: PaginationData): Promise<Event[]>    => await fetchParsePaginated("get", "/event", pd)    as Promise<Event[]>,

    country:  async (id: number): Promise<Country>  => await fetchParse("get", `/country/${id}`)  as Promise<Country>,
    location: async (id: number): Promise<Location> => await fetchParse("get", `/location/${id}`) as Promise<Location>,
    address:  async (id: number): Promise<Address>  => await fetchParse("get", `/address/${id}`)  as Promise<Address>,
    car:      async (id: number): Promise<Car>      => await fetchParse("get", `/car/${id}`)      as Promise<Car>,
    voucher:  async (id: number): Promise<Voucher>  => await fetchParse("get", `/voucher/${id}`)  as Promise<Voucher>,
    driver:   async (id: number): Promise<Driver>   => await fetchParse("get", `/driver/${id}`)   as Promise<Driver>,
    user:     async (id: number): Promise<User>     => await fetchParse("get", `/user/${id}`)     as Promise<User>,
    company:  async (id: number): Promise<Company>  => await fetchParse("get", `/company/${id}`)  as Promise<Company>,
    event:    async (id: number): Promise<Event>    => await fetchParse("get", `/event/${id}`)    as Promise<Event>,
  },
  put: {
    setupEmail: async (setup: EmailSetup) => fetchParse("put", "/setupEmail", setup),

    changePassword: async (passwordChange: PasswordChange) => fetchParse("put", "/user/changePassword", passwordChange),
    resetPassword: async (secret: string, resetPassword: ResetPassword) => fetchParse("put", `/resetPassword/${secret}`, resetPassword),
    resetPasswordEmail: async (email: Email) => fetchParse("put", "/resetPassword", email),
    userActivate: async (id: number, user: User): Promise<User> => await fetchParse("put", `/user/${id}/activate`, user) as Promise<User>,
    userDeactivate: async (id: number, user: User): Promise<User> => await fetchParse("put", `/user/${id}/deactivate`, user) as Promise<User>,

    route: async (id: number, addresses: Address[]): Promise<Route> => await fetchParse("put", `/route/${id}`, addresses) as Promise<Route>,

    companyActivate: async (id: number, company: Company): Promise<Company> => await fetchParse("put", `/company/${id}/activate`, company) as Promise<Company>,
    companyDeactivate: async (id: number, company: Company): Promise<Company> => await fetchParse("put", `/company/${id}/deactivate`, company) as Promise<Company>,

    location: async (id: number, location: Location): Promise<Location> => await fetchParse("put", `/location/${id}`, location) as Promise<Location>,
    address:  async (id: number, address: Address):   Promise<Address>  => await fetchParse("put", `/address/${id}`, address)   as Promise<Address>,
    car:      async (id: number, car: Car):           Promise<Car>      => await fetchParse("put", `/car/${id}`, car)           as Promise<Car>,
    voucher:  async (id: number, voucher: Voucher):   Promise<Voucher>  => await fetchParse("put", `/voucher/${id}`, voucher)   as Promise<Voucher>,
    driver:   async (id: number, driver: Driver):     Promise<Driver>   => await fetchParse("put", `/driver/${id}`, driver)     as Promise<Driver>,
    user:     async (id: number, user: User):         Promise<User>     => await fetchParse("put", `/user/${id}`, user)         as Promise<User>,
    company:  async (id: number, company: Company):   Promise<Company>  => await fetchParse("put", `/company/${id}`, company)   as Promise<Company>,
    event:    async (id: number, event: Event):       Promise<Event>    => await fetchParse("put", `/event/${id}`, event)       as Promise<Event>,
  },
  del: {
    profile:  async (id: number): Promise<ID> => await fetchParse("delete", `/profile/${id}`)  as Promise<ID>,
    location: async (id: number): Promise<ID> => await fetchParse("delete", `/location/${id}`) as Promise<ID>,
    address:  async (id: number): Promise<ID> => await fetchParse("delete", `/address/${id}`)  as Promise<ID>,
    car:      async (id: number): Promise<ID> => await fetchParse("delete", `/car/${id}`)      as Promise<ID>,
    voucher:  async (id: number): Promise<ID> => await fetchParse("delete", `/voucher/${id}`)  as Promise<ID>,
    driver:   async (id: number): Promise<ID> => await fetchParse("delete", `/driver/${id}`)   as Promise<ID>,
    user:     async (id: number): Promise<ID> => await fetchParse("delete", `/user/${id}`)     as Promise<ID>,
    company:  async (id: number): Promise<ID> => await fetchParse("delete", `/company/${id}`)  as Promise<ID>,
    event:    async (id: number): Promise<ID> => await fetchParse("delete", `/event/${id}`)    as Promise<ID>,
  }
}