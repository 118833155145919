import {currentLangStorage} from "./static";
import {env} from "./Environment";

export enum Language {
  LV = 'lv',
  EN = 'en',
}

const fallback = Language.EN;
let currentLang: Language = (localStorage.getItem(currentLangStorage) as Language) || (env.debug ? Language.EN : Language.LV);
const wildcard = "{}";

const langData = {};
for (const lang in Language) {
  if (!Language.hasOwnProperty(lang)) continue;
  langData[Language[lang]] = require(`../Locales/${Language[lang]}.json`);
}

export const initLang = () => {
  currentLang = (localStorage.getItem(currentLangStorage) as Language) || (env.debug ? Language.EN : Language.LV);
}

export const setLang = (newLang: Language): Language => {
  if (!Object.values(Language).includes(newLang)) newLang = fallback;
  currentLang = newLang;
  localStorage.setItem(currentLangStorage, currentLang);
  return currentLang;
};
export const getLang = (): Language => currentLang;
export const getLangName = (lang: Language): string => langData[lang]["locale"] || lang;

export const l  = (name: string, ...opts: any[]): string|any[] => {
  const path = name.split(".");
  let curr = langData[currentLang];
  let currFb = langData[fallback];

  for (const p of path) {
    curr = curr[p];
    currFb = currFb[p];
    if (!curr) {
      if (!currFb) throw new Error(`Unknown locale index: ${name}`);
      else curr = currFb;
    }
  }

  if (curr.includes(wildcard) || opts.length) {
    if (occurrences(curr, wildcard) === opts.length) {
      opts.forEach(item => curr = curr.replace(wildcard, item));
    } else throw new Error("Wrong option length")
  }
  if (typeof curr !== "string" && !Array.isArray(curr)) throw new Error(`Unknown locale index: ${name}`);
  return curr;
};
export const ls = (name: string, ...opts: any[]): string => l(name, ...opts).toString();
export const la = (name: string, ...opts: any[]): any[] => {
  const out = l(name, ...opts);
  if (Array.isArray(out)) return out;
  throw new Error(`${name} is not an Array`);
};

export const formatMonth = (d) => {
  return `${l("calendar.months")[d.getMonth()]} ${d.getFullYear()}`;
};

function occurrences(string: string, subString: string, allowOverlapping = true): number {
  if (subString.length <= 0) return string.length + 1;
  let n = 0, pos = 0, step = allowOverlapping ? 1 : subString.length;
  while (true) {
    pos = string.indexOf(subString, pos);
    if (pos >= 0) {
      ++n;
      pos += step;
    } else break;
  }
  return n;
}
