import {API, Location} from "../../Utils/APIShapes";
import {withContext} from "../../Components/AppContext";

import EditableList, {EditableListProps, EditableListState, ItemPropType} from "./EditableList";
import {dispatchAlert, Severity} from "../../Components/Alerts";


interface State extends EditableListState<Location> {
  items: Location[]
}

class Locations extends EditableList<Location, EditableListProps, State> {
  state: State = {
    activeModal: -1,
    modalOpen: false,
    items: [],
    alertState: 0,
    loading: true
  };

  beforeSave = (cb) => {
    const item = this.state.items[this.state.activeModal];
    if (!item.address.point) {
      dispatchAlert(Severity.WARNING, "Incomplete!");
      return;
    }

    this.setState(s => {
      const items = [...s.items];
      const item2 = {...items[s.activeModal]};
      item2.point = item2.address.point;
      items[s.activeModal] = item2;
      return {items};
    }, cb);
  };

  constructor(props) {
    super(props,
      "location", "titles.locations",
      [
        {name: "address", getValue: item => item.shortName || item.address.fullAddress, type: ItemPropType.LOCATION},
        {name: "shortName", locale: "name"}],
      {get: API.get.locations, del: API.del.location, post: API.post.location, put: API.put.location});
  }

  blankItem = () => ({favorite: false, shortName: "", address: {fullAddress: ""}, point: {lat: 0, lng: 0}});
}

export default withContext(Locations);
