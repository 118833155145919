import React, {Component} from "react";

import {IAppContext} from "../App";
import {withContext} from "../../Components/AppContext";

import {Company} from "../../Utils/APIShapes";
import {l, la} from "../../Utils/locales";
import {setTitle} from "../../Utils/utils";

import "./Settings.scss";
import BP from "../../Components/BP";
import {Button, FormGroup, Input} from "reactstrap";
import CustomSelect from "../../Components/Inputs/CustomSelect";
import Calendar from "../../Components/Inputs/Calendar";

interface Props {
  context: IAppContext
}
interface State {
  company: Company,
  loading: boolean,
  alertState: number
}

class Settings extends Component<Props, State> {
  state = {
    company: {} as Company,
    loading: true,
    alertState: 0
  };

  componentDidMount() {
    this.props.context.setNavBar(true);
    setTitle(l("titles.settings"));
    if (!this.props.context.loaded)
      document.addEventListener("loaded", () => this.setState({company: this.props.context.userCompany!, loading: false}));
    else
      this.setState({company: this.props.context.userCompany!, loading: false});
  }

  save = (ignored) => () => {
    window.alert("Not yet implemented :(")
  };

  refresh = () => {/**/};

  render() {
    const {company, loading} = this.state;
    if (loading || !company || !company.name) return <div>Failed to load...</div>;
    console.log(company);
    return <div className="settings">
      <h1>{l("titles.settings")} [WIP]</h1>
      <BP off="xs">
        <div className="s-card">
          <h2>Company Information</h2>
          <FormGroup>
            <label htmlFor="company-name">Name</label>
            <Input type="text" id="company-name" value={company.name || ""} onChange={({target: {value}}) => this.setState(s => ({company: {...s.company, name: value}}))}/>
          </FormGroup>
          <FormGroup>
            <label htmlFor="company-vat-id">VAT ID</label>
            <Input type="text" pattern="[A-Z0-9]+" id="company-vat-id"/>
          </FormGroup>
          <FormGroup>
            <label htmlFor="company-reg-nr">Registration Number</label>
            <Input type="number" id="company-reg-nr" value={company.regNr || ""} onChange={({target: {value}}) => this.setState(s => ({company: {...s.company, regNr: value}}))}/>
          </FormGroup>
          <FormGroup>
            <label htmlFor="company-address">Street / House number</label>
            <Input type="text" id="company-address"/>
          </FormGroup>
          <FormGroup>
            <label htmlFor="company-postal">Postal code</label>
            <Input type="text" id="company-postal"/>
          </FormGroup>
          <FormGroup>
            <label htmlFor="company-city">City</label>
            <Input type="text" id="company-city"/>
          </FormGroup>
          <FormGroup>
            <label>Country</label>
            <CustomSelect onChange={() => {/**/}}>
              <option>Latvia</option>
            </CustomSelect>
          </FormGroup>
        </div>
        <Button color="success" onClick={this.save("company")}>Save</Button>
        <div className="s-card">
          <h2>Contact Information</h2>
          <FormGroup>
            <label htmlFor="company-phone">Phone</label>
            <Input type="tel" id="company-phone"/>
          </FormGroup>
          <FormGroup>
            <label htmlFor="company-email">E-mail</label>
            <Input type="email" id="company-email"/>
          </FormGroup>
        </div>
        <Button color="success" onClick={this.save("contact")}>Save</Button>
        <div className="s-card">
          <h2>Billing Information</h2>
          <FormGroup>
            <label htmlFor="company-card-holder">Card holder</label>
            <Input type="text" id="company-card-holder"/>
          </FormGroup>
          <FormGroup>
            <label>Credit card</label>
            <CustomSelect onChange={() => {/**/}}>
              <option>Mastercard</option>
            </CustomSelect>
          </FormGroup>
          <FormGroup>
            <label htmlFor="company-card-number">Card number</label>
            <Input type="text" pattern={"(\\d{4} ){3}\\d{4}"} id="company-card-number"/>
          </FormGroup>
          <FormGroup>
            <label>Expires</label>
            <div className="card-exp">
              <CustomSelect onChange={() => {/**/}}>
                {new Array(12).fill(0).map((_, i) => <option key={i}>{`${i+1}`.padStart(2, "0")}</option>)}
              </CustomSelect>
              <CustomSelect onChange={() => {/**/}}>
                {((v) => {
                  return new Array(5).fill(0).map((_, i) => <option key={i}>{`${v+i}`}</option>)
                })(new Date().getFullYear())}
              </CustomSelect>
            </div>
          </FormGroup>
          <FormGroup>
            <label htmlFor="company-card-cvc">CVC code</label>
            <Input type="text" pattern="\d{3}" id="company-card-cvc"/>
          </FormGroup>
        </div>
        <Button color="success" onClick={this.save("billing")}>Save</Button>
        <h2>Calendar settings</h2>
        <h3>Weekly settings</h3>
        <div className="s-card cal-weeks">
          {la("calendar.days").map(v => <div key={v}>{v}<div className={Math.random() > .5 ? "red" : "green"}/></div>)}
        </div>
        <h3>Year settings</h3>
        <Calendar onChange={() => {/**/}} value={new Date()}/>
        <Button color="success" onClick={this.save("calendar")}>Save</Button>
      </BP>
    </div>;
  }
}

export default withContext(Settings);