import React, {Component} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {Container} from "reactstrap";
import Alerts, {dispatchAlertFromError} from "../Components/Alerts";
import {AppContext} from "../Components/AppContext";
import CookieBanner from "../Components/CookieBanner";

import NavBar from "../Components/NavBar";

import {API, Company, User} from "../Utils/APIShapes";
import {env, loadEnv} from "../Utils/Environment";
import {getLang, getLangName, initLang, Language, setLang} from "../Utils/locales";
import {breakpoints} from "../Utils/static";
import {Log} from "../Utils/utils";

import "./App.scss";

import vAPI from "./Dev/APIInfo";
import TestSite from "./Dev/TestSite";
import Home from "./Home";
import Login from "./Login/Login";
import LoginPwdReset from "./Login/LoginPwdReset";
import LoginStep2 from "./Login/LoginStep2";
import Logout from "./Login/Logout";
import NotFound from "./NotFound";
import Cars from "./Settings/Cars";
import Drivers from "./Settings/Drivers";
import Locations from "./Settings/Locations";
import Settings from "./Settings/Settings";
import VouchersEdit from "./Vouchers/Edit";
import Voucher from "./Vouchers/Vouchers";

export let gridSize: undefined|string = undefined;

export interface IAppContext {
  navBar: boolean, setNavBar(b: boolean)
  userData?: User, setUserData(v?: User)
  userCompany?: Company, setCompany(v?: Company)
  userAvailableCompanies?: Company[], setAvailableCompanies(v?: Company[])
  redirect: undefined|string, redirectTo(where: string)
  data: any, setData(d: any), getData(): any
  clearAll(cb?: () => any)
  loaded: boolean
  _counter: number, forceUpdate()
}

class App extends Component<{}, IAppContext> {

  state: IAppContext = {
    navBar: false, setNavBar: b => this.setState({navBar: b}),
    setUserData: (v?: User) => this.setState({userData: v}),
    setCompany: (v?: Company) => this.setState({userCompany: v}),
    setAvailableCompanies: (v?: Company[]) => this.setState({userAvailableCompanies: v}),
    redirect: undefined, redirectTo: where => this.setState({redirect: where}, () => this.setState({redirect: undefined})),
    data: undefined, setData: d => this.setState({data: d}), getData: () => {
      const d = this.state.data;
      this.setState({data: undefined});
      return d;
    },
    clearAll: (cb?) => this.setState({userData: undefined, userCompany: undefined, userAvailableCompanies: undefined, data: undefined}, cb),
    loaded: false,
    _counter: 0, forceUpdate: () => this.setState(s => ({_counter: s._counter + 1}))
  };

  async componentDidMount() {
    await loadEnv();
    Log.d(env);

    initLang();

    this.resizeEvent();
    window.addEventListener('resize', this.resizeEvent);

    if (['/api', '/test'].includes((((document.location || {}).pathname || "").toLowerCase()))) {
      this.setState({loaded: true}, this.resizeEvent);
      return;
    }
    try {
      const ud = await API.get.currentUser();
      const uAC = await API.get.companies({count: 50});
      if (ud.language) setLang(ud.language as Language);
      if (!ud.defaultCompany?.id) {
        this.state.redirectTo("/login/2");
      } else {
        await new Promise(
          resolve => this.setState({userData: ud, userAvailableCompanies: uAC, userCompany: ud.defaultCompany}, () => resolve(null))
        );
      }
    } catch (e) {
      this.state.redirectTo("/login");
    } finally {
      setTimeout(() => {
        this.setState({loaded: true});
        document.dispatchEvent(new Event("loaded"));
      }, 30);
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeEvent)
  }

  resizeEvent = () => {
    let i = 0;
    const bp = Object.keys(breakpoints).sort((a, b) => breakpoints[a].index - breakpoints[b].index);
    while (i < bp.length && window.innerWidth >= breakpoints[bp[i]].min) i++;
    let newSize = bp[i - 1];
    if (newSize === gridSize) return;
    gridSize = newSize
    Log.d("%c Size ", Log.styles.utils, gridSize);
    this.forceUpdate();
  };

  setLang = v => event => {
    event.preventDefault();
    setLang(v);
    this.forceUpdate();
    if (!this.state.userData) return;
    API.put.user(this.state.userData.id, {...this.state.userData, language: v}).catch((e) => {
      Log.e(e);
      dispatchAlertFromError("Failed to update the user!");
    });
  };

  render() {
    if (env.base_url === "?") return null;
    return <AppContext.Provider value={this.state}>
    <BrowserRouter basename={env.base_url}>
    <Route render={({location}) => (<>
      <NavBar hidden={this.state.navBar}/>
      <Alerts/>
      <div style={this.state.loaded ? undefined : {display: "none"}}>
        <Container className="main-container fade-container">
          <Switch location={location}>
            <Route exact path="/" component={Home}/>
            <Route exact path="/login" component={Login}/>
            <Route path="/login/2" component={LoginStep2}/>
            <Route path="/logout" component={Logout}/>
            <Route path="/reset" component={LoginPwdReset}/>
            <Route path="/vouchers/new" component={VouchersEdit}/>
            <Route path="/vouchers/edit/:id" component={VouchersEdit}/>
            <Route path="/vouchers" component={Voucher}/>
            <Route exact path="/settings" component={Settings}/>
            <Route path="/settings/locations" component={Locations}/>
            <Route path="/settings/cars" component={Cars}/>
            <Route path="/settings/drivers" component={Drivers}/>
            <Route path="/api" component={vAPI}/>
            <Route path="/test" component={TestSite}/>
            <Route component={NotFound}/>
          </Switch>
          <div className="spacer"/>
          <div className="copyright">
            © {new Date().getFullYear()} <a href="https://advensis.co/">Advensis</a><br/>
            {Object.values(Language).map((v, k) => [
              !!k && " | ",
              <a href={"/"} style={getLang() === v ? {fontWeight: "bold"} : undefined} key={k} onClick={this.setLang(v)}>{getLangName(v)}</a>
            ])}
          </div>
        </Container>
      </div>
      <CookieBanner/>
      {this.state.redirect ? <Redirect push to={this.state.redirect}/> : undefined}
    </>)}/>
    </BrowserRouter>
    </AppContext.Provider>;
  }
}

export default App;
