import React, {Component} from "react";
import {Redirect} from "react-router-dom";

import {IAppContext} from "./App";

import {withContext} from "../Components/AppContext";


interface Props {
  context: IAppContext
}

interface State {
  loaded: boolean
}

class Home extends Component<Props, State> {
  state: State = {
    loaded: false
  }

  loadEvent = () => this.setState({loaded: true});

  componentDidMount() {
    document.addEventListener("loaded", this.loadEvent);
  }
  componentWillUnmount() {
    document.removeEventListener("loaded", this.loadEvent);
  }

  render() {
    if (!this.state.loaded) return null;
    if (this.props.context.userData) return <Redirect to="/vouchers"/>;
    else return <Redirect to="/login"/>;
  }
}

export default withContext(Home);