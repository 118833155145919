import React from "react";
import {API} from "../../Utils/APIShapes";
import {Log} from "../../Utils/utils";
import {Redirect} from "react-router-dom";
import {withContext} from "../../Components/AppContext";
import {IAppContext} from "../App";

class Logout extends React.Component<{context: IAppContext}> {
  async componentDidMount() {
    console.log("Logout...")
    this.props.context.clearAll();
    API.get.logout().catch(Log.e);
  }

  render() {
    return <Redirect to="/login"/>;
  }
}
export default withContext(Logout);
