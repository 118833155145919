import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Alert, Button, FormGroup, Input, Label} from "reactstrap";

import {IAppContext} from "../App";

import {withContext} from "../../Components/AppContext";

import {l} from "../../Utils/locales";
import {API} from "../../Utils/APIShapes";
import {Log, setTitle} from "../../Utils/utils";


class LoginPwdReset extends Component<{context: IAppContext}> {
  state = {username: "", status: 0};
  onChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  componentDidMount() {
    setTitle(l("login.reset-title"));
    this.props.context.setNavBar(false);
  }

  onSubmit(e) {
    e.preventDefault();
    API.put.resetPasswordEmail({email: this.state.username}).catch(Log.e);
    this.setState({status: 1});
  }

  render() {
    return <div className="login-cont">
      <header><h3>{l("login.reset")}</h3></header>
      <form onSubmit={this.onSubmit.bind(this)}>
        <FormGroup>
          <Label for="username">{l("login.email")}</Label>
          <Input id="username" type="email" name="username" value={this.state.username} onChange={this.onChange.bind(this)} autoFocus required/>
        </FormGroup>
        <Button type="submit">{l("login.reset-btn")}</Button>
        {this.state.status === 1 ? <Alert color="success">{l("login.success")}</Alert> : undefined}
      </form>
      <footer><Link to="/login">{l("back")}</Link></footer>
    </div>;
  }
}

export default withContext(LoginPwdReset);