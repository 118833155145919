import {API, Driver} from "../../Utils/APIShapes";
import {withContext} from "../../Components/AppContext";

import EditableList, {EditableListProps, EditableListState} from "./EditableList";


interface State extends EditableListState<Driver> {
  items: Driver[]
}

class Drivers extends EditableList<Driver, EditableListProps, State> {
  state: State = {
    activeModal: -1,
    modalOpen: false,
    items: [],
    alertState: 0,
    loading: true
  };

  constructor(props) {
    super(props,
      "driver", "titles.drivers",
      ["name", "surname"],
      {get: API.get.drivers, del: API.del.driver, post: API.post.driver, put: API.put.driver});
  }

  blankItem = () => ({name: "", surname: ""});
}

export default withContext(Drivers);
